import moment from 'moment';
import {
  DashboardState,
  DashboardActionType,
  DashboardActionTypeConstant,
} from 'types/dashboard';

const initialState: DashboardState = {
  tutorialVisible: false,
  sharingToken: undefined,
  filter: {
    ref: localStorage.getItem('filterPanelRef') || undefined,
    channel: localStorage.getItem('filterPanelChannel') || undefined,
    datesRange: {
      startDate: localStorage.getItem('filterPanelStartDate')
        ? moment(new Date(localStorage.getItem('filterPanelStartDate') || ''))
        : moment().subtract(6, 'd'),
      endDate: localStorage.getItem('filterPanelEndDate')
        ? moment(new Date(localStorage.getItem('filterPanelEndDate') || ''))
        : moment(),
    },
  },
  refs: {
    list: [],
    loading: true,
    error: '',
  },
  channels: {
    list: [],
    loading: true,
    error: '',
  },
  intents: {
    default: undefined,
    list: [],
    loading: true,
    error: '',
  },
  intentMessages: { intent: '', list: [], loading: false },
  current: null,
  list: {
    all: {
      name: 'All metrics',
      loaded: false,
    },
    users: {
      name: 'Users',
      loaded: false,
    },
    activity: {
      name: 'Activity',
      loaded: false,
    },
    conversations: {
      name: 'Conversations',
      loaded: false,
    },
  },
  filterPanelHidden: localStorage.getItem('filterPanelHidden') === 'true',
  siderMenuItem: 'all',
  dbDownload: {
    loading: false,
    error: '',
  },
};

const dashboardReducer = (
  state = initialState,
  action: DashboardActionType,
): DashboardState => {
  switch (action.type) {
    case DashboardActionTypeConstant.UPDATE_REF: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ref: action.ref,
        },
      };
    }

    case DashboardActionTypeConstant.UPDATE_CHANNEL: {
      return {
        ...state,
        filter: {
          ...state.filter,
          channel: action.channel,
        },
      };
    }

    case DashboardActionTypeConstant.UPDATE_DATES_RANGE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          datesRange: action.datesRange,
        },
      };
    }

    case DashboardActionTypeConstant.DELETE_INTENT_MESSAGE: {
      return {
        ...state,
        intentMessages: { intent: '', list: [], loading: false },
      };
    }

    case DashboardActionTypeConstant.GET_INTENT_MESSAGE: {
      return {
        ...state,
        intentMessages: { intent: '', list: [], loading: true },
      };
    }

    case DashboardActionTypeConstant.GET_INTENT_MESSAGE_SUCCESS: {
      return {
        ...state,
        intentMessages: {
          intent: action.intent,
          list: action.messages,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.GET_INTENT_MESSAGE_FAILURE: {
      return {
        ...state,
        intentMessages: {
          intent: '',
          list: action.error,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.GET_REFS_LIST: {
      return {
        ...state,
        refs: {
          ...state.refs,
          loading: true,
          error: '',
        },
      };
    }

    case DashboardActionTypeConstant.GET_REFS_LIST_SUCCESS: {
      return {
        ...state,
        refs: {
          ...state.refs,
          list: action.data,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.GET_REFS_LIST_FAILURE: {
      return {
        ...state,
        refs: {
          ...state.refs,
          error: action.error,
        },
      };
    }

    case DashboardActionTypeConstant.GET_CHANNEL_LIST: {
      return {
        ...state,
        channels: {
          ...state.channels,
          loading: true,
          error: '',
        },
      };
    }

    case DashboardActionTypeConstant.GET_CHANNEL_LIST_SUCCESS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          list: action.data,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.GET_CHANNEL_LIST_FAILURE: {
      return {
        ...state,
        channels: {
          ...state.channels,
          error: action.error,
        },
      };
    }

    case DashboardActionTypeConstant.GET_INTENTS_LIST: {
      return {
        ...state,
        intents: {
          ...state.intents,
          loading: true,
          error: '',
        },
      };
    }

    case DashboardActionTypeConstant.GET_INTENTS_LIST_SUCCESS: {
      return {
        ...state,
        intents: {
          ...state.intents,
          default: action.data[0],
          list: action.data.sort(),
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.GET_INTENTS_LIST_FAILURE: {
      return {
        ...state,
        intents: {
          ...state.intents,
          error: action.error,
        },
      };
    }

    case DashboardActionTypeConstant.SWITCH_SIDER_MENU_ITEM: {
      return {
        ...state,
        siderMenuItem: action.siderMenuItem,
      };
    }

    case DashboardActionTypeConstant.DASHBOARD_DOWNLOAD: {
      return {
        ...state,
        dbDownload: {
          ...state.dbDownload,
          loading: true,
          error: '',
        },
      };
    }

    case DashboardActionTypeConstant.DASHBOARD_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        dbDownload: {
          ...state.dbDownload,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.DASHBOARD_DOWNLOAD_FAILURE: {
      return {
        ...state,
        dbDownload: {
          ...state.dbDownload,
          error: action.error,
          loading: false,
        },
      };
    }

    case DashboardActionTypeConstant.SET_CURRENT_DASHBOARD: {
      return {
        ...state,
        current: action.key && { key: action.key, ...state.list[action.key] },
      };
    }

    case DashboardActionTypeConstant.SET_DASHBOARD_LOADED: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.key]: {
            ...state.list[action.key],
            loaded: true,
          },
        },
      };
    }

    case DashboardActionTypeConstant.RESET_DASHBOARDS_LOADED: {
      return {
        ...state,
        list: Object.entries(state.list).reduce(
          (prev, [key, val]) => ({ ...prev, [key]: { ...val, loaded: false } }),
          {},
        ),
      };
    }

    case DashboardActionTypeConstant.TOGGLE_FILTER_PANEL: {
      return {
        ...state,
        filterPanelHidden: !state.filterPanelHidden,
      };
    }

    case DashboardActionTypeConstant.SET_SHARING_TOKEN: {
      return {
        ...state,
        sharingToken: action.token,
      };
    }

    case DashboardActionTypeConstant.SET_TUTORIAL_VISIBLE: {
      return {
        ...state,
        tutorialVisible: action.visible,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
