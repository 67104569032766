import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Modal, Space } from 'antd';
import {
  DownloadOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { renderMetricPanel, renderMetricActionButton } from 'helpers/renderers';
import { useMetricPlan } from 'helpers/hooks';
import { RootState } from 'reducers';
import {
  MetricPanel,
  MetricPanelOptionsSelected,
  MetricName,
} from 'types/metrics';
import { ResponseMsgBox } from 'components/ui';
import { withPlan } from 'helpers/commonComponents';

import MetricFull from './MetricFull';
import MetricPlaceholder from './MetricPlaceholder';

const MetricWrapper = styled.div<{ size: number; height?: number }>`
  width: ${props => 100 / props.size}%;
  min-width: 350px;
  min-height: ${({ height, size }) =>
    height ? `${100 / height}` : `${100 / size}`}vh;
  padding: 5px;

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 10px;
    padding: 20px 24px 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 20px;
`;

const Title = styled.h3`
  display: flex;
  flex-basis: 30%;
  flex-grow: 1;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;

  margin: 0;
`;

const ActionsPanel = styled(Space)`
  margin-left: 10px;
  align-items: flex-start;
`;

const FilterPanel = styled(Space)`
  width: 100%;
  margin-top: 6px;
`;

const openInfoModal = (title: string, content: string) => {
  Modal.info({
    title,
    maskClosable: true,
    content: <p>{content}</p>,
    icon: <InfoCircleOutlined style={{ color: gold[5] }} />,
    onOk() {},
  });
};

const withBoards = (
  component: JSX.Element | null,
  metricBoards: string[],
  currentBoard?: string,
) =>
  currentBoard !== undefined && metricBoards.includes(currentBoard)
    ? component
    : null;

export type MetricProps = {
  metricKey: MetricName;
  empty?: boolean;
  size: number;
  height?: number;
  aspect?: number;
  onReload: (options?: MetricPanelOptionsSelected | null) => void;
  panels?: MetricPanel[];
  children?: JSX.Element;
};

type Props = {
  type: string;
  download?: () => void;
} & MetricProps &
  ReturnType<typeof mapStateToProps>;

const Metric = ({
  metricKey,
  metric,
  children,
  plan,
  type,
  empty = false,
  size,
  height,
  onReload,
  panels,
  download,
  currentDashboard,
  userLoading,
}: Props) => {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = useState(false);

  const granted = useMetricPlan(metricKey, plan);

  const { title, boards, error, loaded } = useMemo(() => metric[metricKey], [
    metric,
    metricKey,
  ]);

  const actionButtons = useMemo(
    () => [
      {
        key: 'download',
        type: 'link',
        text: 'Download',
        icon: <DownloadOutlined />,
        visible: download !== undefined,
        onClick: download,
      },
      {
        key: 'info',
        type: 'link',
        text: 'Info',
        icon: <InfoCircleOutlined />,
        visible: true,
        onClick: () => {
          openInfoModal(t(title), t(`${metricKey}-info`));
        },
      },
      {
        key: 'reload',
        type: 'link',
        text: 'Reload',
        icon: <ReloadOutlined />,
        visible: true,
        onClick: () => onReload(null),
      },
      {
        key: 'fullscreen',
        type: 'link',
        text: 'Fullscreen',
        icon: <FullscreenOutlined />,
        visible: true,
        onClick: () => setFullscreen(true),
      },
    ],
    [download, metricKey, onReload, t, title],
  );

  const childrenWrapped = useMemo(
    () =>
      type === 'table' ? (
        children || null
      ) : (
        <MetricPlaceholder loaded={loaded && !userLoading} empty={empty}>
          {children}
        </MetricPlaceholder>
      ),
    [children, userLoading, empty, loaded, type],
  );

  return withBoards(
    <MetricWrapper size={size} height={height}>
      <div className="container">
        <Header>
          <Title title={title}>{t(title)}</Title>

          <ActionsPanel size={4}>
            {granted
              ? actionButtons.map(button =>
                  renderMetricActionButton({
                    ...button,
                    type: 'link',
                    text: undefined,
                  }),
                )
              : renderMetricActionButton({
                  ...actionButtons.filter(({ key }) => key === 'info')[0],
                  type: 'link',
                  text: undefined,
                })}
          </ActionsPanel>

          {granted && (
            <FilterPanel size={12}>
              {panels?.map(panel => renderMetricPanel(panel, onReload))}
            </FilterPanel>
          )}
        </Header>

        <ResponseMsgBox error={t(error)} />

        {withPlan(childrenWrapped, granted)}
      </div>

      <MetricFull
        title={title}
        type={type}
        visible={fullscreen}
        setVisible={setFullscreen}
        metric={childrenWrapped}
        panels={panels}
        actionButtons={actionButtons.filter(btn => btn.key !== 'fullscreen')}
        onReload={onReload}
      />
    </MetricWrapper>,
    boards,
    currentDashboard,
  );
};

const mapStateToProps = (store: RootState) => ({
  userLoading: store.account.user.loading,
  plan: store.account.user.data.plan,
  metric: store.metrics.metric,
  currentDashboard: store.dashboard.current?.key,
});

export default connect(mapStateToProps)(Metric);
