import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Form, Input, Checkbox, Button } from 'antd';
import {
  MailOutlined,
  UnlockOutlined,
  GlobalOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { signUp } from 'actions/account';
import { RootState } from 'reducers';
import { SignUpFormData } from 'types/account';
import { ResponseMsgBox } from 'components/ui';

import { FormField, HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SignUp = ({ error, prompt, signUpUser }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('Register a new account')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <Form
        initialValues={{ accepted: false }}
        onFinish={(data: SignUpFormData) => signUpUser(data)}
      >
        <FormField
          name="name"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter your full name'),
            },
          ]}
        >
          <Input
            size="large"
            type="text"
            prefix={<UserOutlined style={{ color: gold[5] }} />}
            placeholder={t('Full name')}
          />
        </FormField>

        <FormField
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter valid email'),
              type: 'email',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: gold[5] }} />}
            placeholder={t('Email')}
          />
        </FormField>

        <FormField name="website" hasFeedback>
          <Input
            size="large"
            type="text"
            prefix={<GlobalOutlined style={{ color: gold[5] }} />}
            placeholder={t('Company website')}
          />
        </FormField>

        <FormField
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter password'),
            },
            { min: 8, message: t('Password must be at least 8 symbols') },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('Password')}
          />
        </FormField>

        <FormField
          name="accepted"
          valuePropName="checked"
          rules={[
            {
              required: true,
              transform: (value: boolean) => value || undefined,
              type: 'boolean',
              message: t('You must accept the Terms of Service'),
            },
          ]}
        >
          <Checkbox>
            {`${t('I agree to the')} `}
            <a href="/">{t('Terms of Service')}</a>
          </Checkbox>
        </FormField>

        <FormField>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            style={{ borderRadius: '4px' }}
          >
            {t('Create account')}
          </Button>
        </FormField>
      </Form>

      <HelpMessage>
        {`${t('Already have an account?')} `}
        <Link to="/auth/login">{t('Log in now')}</Link>
      </HelpMessage>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  error: store.account.signUp.error,
  prompt: store.account.signUp.prompt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signUpUser: signUp,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
