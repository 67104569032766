/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';

import { Divider, Spin } from 'antd';
import styled from 'styled-components';

import { RootState } from 'reducers';
import { DashboardState } from 'types/dashboard';
import { clearIntentMessage } from 'actions/dashboard';

const TooltipWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  color: #000;
  padding: 14px;
  width: 300px;
`;

const InfoLabel = styled.p`
  margin: 0px;
`;

const FilterLabel = styled.p`
  color: #828282;
  margin-top: 12px;
  margin-bottom: 0px;
`;

const IntentExampleWrapper = styled.div`
  font-size: 12px;
`;

const IntentExampleTitle = styled.div`
  font-weight: 500;
`;

type Props = {
  payload?: { dataKey: string; value: number }[];
  label?: string;
  active?: boolean;
};

const IntentsTooltip = ({ payload, label, active }: Props) => {
  const intentMessages = useSelector<
    RootState,
    DashboardState['intentMessages']
  >(state => state.dashboard.intentMessages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!active) {
      dispatch(clearIntentMessage());
    }
  }, [active, dispatch]);

  return active && label && payload ? (
    <TooltipWrapper>
      <InfoLabel>{label}</InfoLabel>
      <InfoLabel
        style={{ color: '#8884d8' }}
      >{`${payload[0].dataKey}: ${payload[0].value}`}</InfoLabel>
      {intentMessages?.intent === label ? (
        <IntentExampleWrapper>
          <Divider dashed style={{ margin: '6px 0px' }} />
          <IntentExampleTitle>
            <Trans>Example bot messages</Trans>
          </IntentExampleTitle>
          {intentMessages?.list?.map((msg, i) => (
            <Fragment key={`msg-${i}`}>
              <p style={{ marginBottom: '0' }}>{msg}</p>
              {i + 1 < intentMessages.list.length && (
                <Divider dashed style={{ margin: '16px 0 12px' }} />
              )}
            </Fragment>
          ))}
        </IntentExampleWrapper>
      ) : intentMessages.loading ? (
        <Spin spinning style={{ margin: '6px auto 2px' }} />
      ) : (
        <FilterLabel>
          <Trans>Click to see bot message</Trans>
        </FilterLabel>
      )}
    </TooltipWrapper>
  ) : null;
};

export default IntentsTooltip;
