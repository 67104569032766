import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { bindActionCreators, Dispatch } from 'redux';
import moment, { Moment } from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Spin, Empty } from 'antd';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import * as toolsActions from 'actions/tools';
import { RootState } from 'reducers';
import { ClusteringOptions } from 'types/tools';
import { DashboardState } from 'types/dashboard';
import { usePromptError, useSectionPlan } from 'helpers/hooks';
import { ToolsFilter, withPlan, openInfoModal } from 'helpers/commonComponents';
import { killClusteringJob } from 'actions/tools';
import { downloadClusteringCSV } from 'actions/dashboard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const PlotWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 84%;
  overflow: hidden;
`;

const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
`;

const LoadingItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-spin-lg .ant-spin-dot {
    font-size: 46px;
  }
  .ant-spin-lg .ant-spin-dot i {
    width: 20px;
    height: 20px;
  }
`;

const LoadingText = styled.p`
  color: #262626;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 20px;
`;

const AbortButton = styled(Button)`
  background: #fff2e8;
  border: 1px solid #ffbb96;
  color: #fa541c;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 20px;

  height: 31px;
  width: 76px;
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Clustering = ({
  bots,
  clustering,
  resetClustering,
  getClustering,
  checkClustering,
  getClusteringActiveJob,
}: Props) => {
  const { t } = useTranslation();
  const plotRef = useRef<HTMLDivElement>(null);

  const [params, setParams] = useState({} as ClusteringOptions);

  const dbDownload = useSelector<RootState, DashboardState['dbDownload']>(
    state => state.dashboard.dbDownload,
  );
  const granted = useSectionPlan('clustering');
  const dispatch = useDispatch();

  const [dates, setDates] = useState<Moment[]>([]);

  const actionButtons = [
    {
      key: 'download',
      type: 'link',
      icon: dbDownload.loading ? <LoadingOutlined /> : <DownloadOutlined />,
      visible: clustering.jobId !== null && !clustering.loading,
      onClick: () =>
        dispatch(
          downloadClusteringCSV(
            clustering.jobId as number,
            bots.list.find(bot => bot.id === params.botId)?.name,
            {
              startDate: params.datesRange?.[0],
              endDate: params.datesRange?.[1],
            },
          ),
        ),
    },
    {
      key: 'info',
      type: 'link',
      icon: <InfoCircleOutlined />,
      visible: true,
      onClick: () => {
        openInfoModal(t('Clustering'), t('ClusteringInfo'));
      },
    },
  ];

  const handleAbortClustering = () => {
    if (clustering.jobId) {
      dispatch(killClusteringJob(clustering.jobId));
    }
  };

  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return current > moment().endOf('day');
    }
    const tooLate =
      (dates[0] && current.diff(dates[0], 'days') > 6) ||
      current > moment().endOf('day');
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
    return tooEarly || tooLate;
  };

  useEffect(() => {
    granted && getClusteringActiveJob();
  }, [getClusteringActiveJob, granted]);

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const jobId = clustering.jobId;

    if (jobId !== null && clustering.loading) {
      const interval = setInterval(() => checkClustering(jobId), 5000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [clustering.jobId, checkClustering, clustering.loading]);

  useEffect(() => {
    resetPlot();
    resetClustering();
    setParams(prevParams => ({
      ...prevParams,
      ref: undefined,
      intent: undefined,
      channel: undefined,
    }));
  }, [resetClustering]);

  usePromptError(clustering.prompt, clustering.error);

  const resetPlot = () => {
    if (plotRef.current) {
      plotRef.current.innerHTML = '';
    }
  };

  const filterConfig = {
    search: {
      visible: false,
      text: '',
    },
    intent: true,
    startButton: 'Launch',
    dateProps: {
      disabledDate,
      onCalendarChange: (value: [Moment, Moment]) => {
        setDates(value);
      },
      ranges: {
        [t('1 day')]: [moment(), moment()],
        [t('2 days')]: [moment().subtract(1, 'd'), moment()],
        [t('Week')]: [moment().subtract(6, 'd'), moment()],
      },
    },
  };

  const handleFormFinish = () => {
    resetPlot();
    getClustering(params);
  };

  return withPlan(
    <Wrapper>
      <ToolsFilter
        mainData={clustering}
        filterConfig={filterConfig}
        actionButtons={actionButtons}
        handleFormFinish={handleFormFinish}
        params={params}
        setParams={setParams}
      />

      <PlotWrapper>
        {clustering.loading && (
          <LoadingContainer>
            <LoadingItemsContainer>
              <Spin spinning size="large" />
              <LoadingText>Clustering is in progress...</LoadingText>
              <AbortButton onClick={handleAbortClustering}>Abort</AbortButton>
            </LoadingItemsContainer>
          </LoadingContainer>
        )}
        {clustering.prompt === null && !clustering.loading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 'auto' }}
          />
        )}
        <div id="bk-clustering" className="bk-root" ref={plotRef} />
      </PlotWrapper>
    </Wrapper>,
    granted,
  );
};

const mapStateToProps = (store: RootState) => ({
  bots: store.account.bots,
  clustering: store.tools.clustering,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetClustering: toolsActions.resetClustering,
      getClustering: toolsActions.getClustering,
      getClusteringActiveJob: toolsActions.getClusteringActiveJob,
      checkClustering: toolsActions.checkClustering,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Clustering);
