import domtoimage from 'dom-to-image';
import moment, { Moment } from 'moment';
import i18n from 'i18next';
import {
  ChartData,
  SankeyDataBackend,
  SankeyData,
  TableDataBackend,
  TableData,
  ScatterDataBackend,
  ScatterData,
} from 'types/metrics';
import { DatesRange } from 'types/dashboard';

export const capitalize = (word: string | null) =>
  word && word.length > 0 ? `${word[0].toUpperCase()}${word.slice(1)}` : '';

export const isTrialExpired = (trialExpDate: Moment | null) =>
  trialExpDate === null ? false : trialExpDate.diff(moment()) < 0;

export const getDatesRange = ({ startDate, endDate }: DatesRange): string[] => {
  const numDays = endDate.diff(startDate, 'd') + 1;
  return [...Array(numDays).keys()].reverse().map(i =>
    endDate
      .clone()
      .subtract(i, 'd')
      .format('DD-MM-YYYY'),
  );
};

export const transposeArray = (arr: any[][]) =>
  arr[0].map((_, colIndex) => arr.map(row => row[colIndex]));

export const mapToChartData = (
  data: ChartData,
  datesRange: DatesRange,
): ChartData => {
  const dataFormatted = data.map(record => {
    const [year, month, day] = (record.date as string).split('-');
    return {
      date: `${day}-${month}-${year}`,
      count: +record.count,
    };
  });

  return getDatesRange(datesRange).map((date: string) => ({
    date: date.slice(0, date.lastIndexOf('-')),
    count:
      dataFormatted.filter(rec => rec.date === date)[0] !== undefined
        ? dataFormatted.filter(rec => rec.date === date)[0].count
        : 0,
  }));
};

export const mapToIntentsChartData = (
  data: { intent: string; count: string }[],
): ChartData => {
  return data.map(record => {
    return {
      intent: record.intent,
      count: +record.count,
    };
  });
};

export const mapToTableData = (
  data: TableDataBackend,
  header: { title: string; key: string; sorter?: Function }[],
): TableData => ({
  columns: header.map(column => ({
    title: column.title,
    dataIndex: column.key,
    key: column.key,
    sorter: column.sorter,
  })),
  rows: data.map((intent, key) => ({
    key: key.toString(),
    ...intent,
  })),
});

export const mapToSankeyData = (data: SankeyDataBackend): SankeyData => {
  const maxFlowIndex = Math.max(...data.map(flow => flow.intents.length)) - 1;

  const formattedData = data.map(flow => ({
    intents: flow.intents.map(
      (intent, i) =>
        `${intent}|${i === flow.intents.length - 1 ? maxFlowIndex : i}`,
    ),
    count: +flow.count,
  }));

  const nodes = [...new Set(formattedData.map(flow => flow.intents).flat())];
  const links = formattedData
    .map(flow =>
      flow.intents.slice(0, -1).map((intent, i) => ({
        source: nodes.indexOf(intent),
        target: nodes.indexOf(flow.intents[i + 1]),
        value: flow.count,
      })),
    )
    .flat();

  const uniqueLinks = [
    ...new Set(links.map(link => `${link.source}-${link.target}`)),
  ]
    .map(link1 =>
      links.filter(
        link2 =>
          link2.source === +link1.split('-')[0] &&
          link2.target === +link1.split('-')[1],
      ),
    )
    .map(unqLinks =>
      unqLinks.reduce((p, c) => ({
        source: c.source,
        target: c.target,
        value: p.value + c.value,
      })),
    );

  return {
    nodes: nodes.map(intent => ({ name: intent.split('|')[0] })),
    links: uniqueLinks,
  };
};

type TreeNode = {
  value: string;
  label: string;
  children?: (TreeNode | number)[];
};

export const mapSankeyToTree = (data: SankeyData): TreeNode[] => {
  if (!data.nodes.length) {
    return [];
  }

  const nodes: TreeNode[] = data.nodes.map(
    ({ name }: { name: string }, nodeIdx: number) => ({
      value: name,
      label: name,
      children: data.links
        .filter(({ source }: { source: number }) => source === nodeIdx)
        .map(({ target }: { target: number }) => target),
    }),
  );

  const getTreeNode = (node: TreeNode): TreeNode => {
    if (!node.children?.length) {
      return {
        value: node.value,
        label: node.label,
      };
    }

    return {
      ...node,
      children: node.children?.map((childNode: TreeNode | number) => {
        if (typeof childNode === 'number') {
          return getTreeNode(nodes[childNode]);
        }

        return childNode;
      }),
    };
  };

  nodes.forEach((node: TreeNode, nodeIdx: number) => {
    nodes[nodeIdx] = getTreeNode(node);
  });

  return [nodes[0]];
};

export const mapToScatterData = (data: ScatterDataBackend): ScatterData => {
  const weekday =
    i18n.languages[0] === 'ru'
      ? ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
      : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return data
    .map(({ date, time }) =>
      time.map(({ hour, count }) => ({
        date: weekday[+date - 1],
        hour: +hour,
        count: +count,
      })),
    )
    .flat();
};

export const getChartMargins = (
  key: keyof ChartData[0],
  chartData: ChartData = [],
) => {
  const startPos = -40;
  const gap = 10;

  let minWordLen = 1;
  let maxNum = 0;

  const maxWordLen = Math.max(
    ...chartData.map((chartField: ChartData[0]) => {
      const wordLen =
        typeof chartField[key] === 'number'
          ? (chartField[key] as number).toFixed().length
          : (chartField[key] as string).length + 1;

      if (typeof (chartField[key] === 'number') && chartField[key] > maxNum) {
        maxNum = chartField[key] as number;
      }

      return wordLen;
    }),
  );

  // if max val is less than 4 and not zero, the numbers w/ 2 decimal places appear on a chart axis
  if (maxNum !== 0 && maxNum < 4) {
    minWordLen = 3;
  }

  return {
    left: startPos + gap + Math.max(maxWordLen, minWordLen) * 8,
    right: 10,
    top: 5,
    bottom: 10,
  };
};

export const getImage = async (
  domElem: HTMLDivElement,
  width: number = 1920,
) => {
  const widthInit = domElem.clientWidth;
  const heightInit = domElem.clientHeight;
  const scale = width / widthInit;
  const height = heightInit * scale;

  const image = await domtoimage.toPng(domElem, {
    width,
    height,
    style: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: widthInit,
      height: heightInit,
      display: 'inline',
      transform: `translate(${width / 2 - widthInit / 2}px, ${height / 2 -
        heightInit / 2}px) scale(${scale})`,
    },
  });

  return {
    file: image,
    width,
    height,
  };
};

export const getFileUrl = async (img: File | Blob) => {
  return new Promise<any>(resolve => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(img);
  });
};

export const remEmptyFields = (obj: Object) => {
  const newObj: { [key: string]: string } = {};

  Object.entries(obj).forEach(([key, val]: string[]) => {
    if (val !== undefined) {
      newObj[key] = val;
    }
  });

  return newObj;
};

export const getRelativeDate = (numSecsAgo: number) => {
  if (numSecsAgo > 86400) {
    const numDays = Math.floor(numSecsAgo / 86400);
    const numHours = Math.floor((numSecsAgo - numDays * 86400) / 3600);
    return `${numDays}d ${numHours}h`;
  }
  if (numSecsAgo > 1800) {
    return `${Math.floor(numSecsAgo / 3600)}h`;
  }
  return '< 30min';
};

export const switchLanguage = (language: string) => {
  i18n.changeLanguage(language);
  localStorage.setItem('locale', `${language}`);
};

type ArrayOfObjects = {
  key: string;
  [key: string]: any;
}[];

export const mergeArraysByKey = <
  Arr1 extends ArrayOfObjects,
  Arr2 extends ArrayOfObjects
>(
  arr1: Arr1,
  arr2: Arr2,
  key: string,
): Arr1 & Arr2 =>
  arr1.map(arr1Item => {
    const same = arr2.find(arr2Item => arr2Item[key] === arr1Item[key]) || {};
    return { ...arr1Item, ...same };
  }) as Arr1 & Arr2;
