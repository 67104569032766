import { AnyAction } from 'redux';
import { Moment } from 'moment';
import { TableData } from 'types/metrics';

// Action constants

export enum ToolsActionTypeConstant {
  CREATE_REF_LINK = 'CREATE_REF_LINK',
  CREATE_REF_LINK_SUCCESS = 'CREATE_REF_LINK_SUCCESS',
  CREATE_REF_LINK_FAILURE = 'CREATE_REF_LINK_FAILURE',
  RESET_CLUSTERING = 'RESET_CLUSTERING',
  GET_CLUSTERING = 'GET_CLUSTERING',
  GET_CLUSTERING_SUCCESS = 'GET_CLUSTERING_SUCCESS',
  GET_CLUSTERING_FAILURE = 'GET_CLUSTERING_FAILURE',
  CHECK_CLUSTERING_SUCCESS = 'CHECK_CLUSTERING_SUCCESS',
  GET_ALERTS = 'GET_ALERTS',
  GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS',
  GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE',
  CREATE_ALERT = 'CREATE_ALERT',
  CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS',
  CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE',
  DELETE_ALERT = 'DELETE_ALERT',
  DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS',
  DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE',
  EDIT_ALERT = 'EDIT_ALERT',
  EDIT_ALERT_SUCCESS = 'EDIT_ALERT_SUCCESS',
  EDIT_ALERT_FAILURE = 'EDIT_ALERT_FAILURE',
  GET_USERS_MESSAGES = 'GET_USERS_MESSAGES',
  GET_USERS_MESSAGES_SUCCESS = 'GET_USERS_MESSAGES_SUCCESS',
  GET_USERS_MESSAGES_FAILURE = 'GET_USERS_MESSAGES_FAILURE',
  GET_MISUNDERSTOOD_SUM = 'GET_MISUNDERSTOOD_SUM',
  GET_MISUNDERSTOOD_SUM_SUCCESS = 'GET_MISUNDERSTOOD_SUM_SUCCESS',
  GET_MISUNDERSTOOD_SUM_FAILURE = 'GET_MISUNDERSTOOD_SUM_FAILURE',
}

// Tools state type

export type ToolsState = {
  alerts: {
    list: Alert[];
    loading: boolean;
    prompt: string | null;
    error: string | null;
  };
  refLink: {
    link: string | null;
    loading: boolean;
    prompt: string | null;
    error: string | null;
  };
  clustering: {
    jobId: number | null;
    loading: boolean;
    prompt: string | null;
    error: string | null;
  };
  searchMessage: {
    list: MessageData[];
    loading: boolean;
    prompt: string | null;
    error: string | null;
  };
  misunderstoodSum: {
    list: SummarizationData[];
    loading: boolean;
    error: string;
  };
};

// Asynchronous action types

export type RefLinkData = {
  bot: string;
  botUrl: string;
  campaignContent: string;
  campaignMedium: string;
  campaignName: string;
  campaignSource: string;
  campaignTerm: string;
  referral: string;
  referralSource: string;
};

export type MessageData = {
  key: number;
  intent: string;
  message: string;
  timeStamp: string;
  userId: string;
};

export type ClusteringOptions = {
  ref?: string;
  intent?: string;
  channel?: string;
  datesRange: [Moment, Moment];
  botId: number;
};

export type SearchMessagesOptions = {
  message?: string;
  ref?: string;
  intent?: string;
  channel?: string;
  datesRange: [Moment, Moment];
  botId: number;
};

export type SummarizationOptions = {
  ref?: string;
  channel?: string;
  datesRange: [Moment, Moment];
  botId: number;
};

export type SelectOptions = {
  label?: string;
  value: string | number;
}[];

export type AlertManagerPanelsConfig = {
  type: number;
  requiredFields: string[];
  items: AlertManagerPanelItem[];
  msg: string;
}[];

export type AlertManagerPanelItem = {
  key: string;
  type: string;
  loading?: boolean;
  placeholder?: string;
  dependencies?: string[];
  options?: SelectOptions;
  defaultValue?: string | number;
  onChange?: Function;
};

export type Alert = {
  id: number;
  ref?: string;
  botId: number;
  type: number | string;
  payload: number;
  status: number;
  timestamp: string;
};

export type AlertData = {
  type: number;
  botId: number;
  payload: number;
  ref?: string;
};

export type SummarizationData = {
  key: number;
  message: string;
};

// Action types

export interface CreateRefLink extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface CreateRefLinkSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  link: string;
  prompt: string;
}

export interface CreateRefLinkFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface ResetClustering extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface GetClustering extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface GetClusteringSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  prompt: string;
  jobId: number;
}

export interface GetClusteringFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface CheckClusteringSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  prompt: string;
}

export interface GetAlerts extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface GetAlertsSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  data: Alert[];
  prompt: string;
}

export interface GetAlertsFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface CreateAlert extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface CreateAlertSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  data: Alert;
  prompt: string;
}

export interface CreateAlertFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface DeleteAlert extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface DeleteAlertSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  id: number;
  prompt: string;
}

export interface DeleteAlertFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface EditAlert extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface EditAlertSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  data: Alert;
  oldID: number;
  prompt: string;
}

export interface EditAlertFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface GetMessages extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface GetMessagesSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  data: MessageData[];
}

export interface GetMessagesFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export interface GetMisunderstoodSum extends AnyAction {
  type: ToolsActionTypeConstant;
}

export interface GetMisunderstoodSumSuccess extends AnyAction {
  type: ToolsActionTypeConstant;
  data: TableData;
}

export interface GetMisunderstoodSumFailure extends AnyAction {
  type: ToolsActionTypeConstant;
  error: string;
}

export type ToolsActionType =
  | CreateRefLink
  | CreateRefLinkSuccess
  | CreateRefLinkFailure
  | ResetClustering
  | GetClustering
  | GetClusteringSuccess
  | GetClusteringFailure
  | CheckClusteringSuccess
  | GetAlerts
  | GetAlertsSuccess
  | GetAlertsFailure
  | CreateAlert
  | CreateAlertSuccess
  | CreateAlertFailure
  | DeleteAlert
  | DeleteAlertSuccess
  | DeleteAlertFailure
  | EditAlert
  | EditAlertSuccess
  | EditAlertFailure
  | GetMessages
  | GetMessagesSuccess
  | GetMessagesFailure
  | GetMisunderstoodSum
  | GetMisunderstoodSumSuccess
  | GetMisunderstoodSumFailure;
