import { AnyAction } from 'redux';
import { Moment } from 'moment';

// Action constants

export enum DashboardActionTypeConstant {
  SWITCH_SIDER_MENU_ITEM = 'SWITCH_SIDER_MENU_ITEM',
  DASHBOARD_DOWNLOAD = 'DASHBOARD_DOWNLOAD',
  DASHBOARD_DOWNLOAD_SUCCESS = 'DASHBOARD_DOWNLOAD_SUCCESS',
  DASHBOARD_DOWNLOAD_FAILURE = 'DASHBOARD_DOWNLOAD_FAILURE',
  SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD',
  SET_DASHBOARD_LOADED = 'SET_DASHBOARD_LOADED',
  TOGGLE_FILTER_PANEL = 'TOGGLE_FILTER_PANEL',
  RESET_DASHBOARDS_LOADED = 'RESET_DASHBOARDS_LOADED',
  UPDATE_REF = 'UPDATE_REF',
  UPDATE_CHANNEL = 'UPDATE_CHANNEL',
  UPDATE_DATES_RANGE = 'UPDATE_DATES_RANGE',
  GET_REFS_LIST = 'GET_REFS_LIST',
  GET_REFS_LIST_SUCCESS = 'GET_REFS_LIST_SUCCESS',
  GET_REFS_LIST_FAILURE = 'GET_REFS_LIST_FAILURE',
  GET_CHANNEL_LIST = 'GET_CHANNEL_LIST',
  GET_CHANNEL_LIST_SUCCESS = 'GET_CHANNEL_LIST_SUCCESS',
  GET_CHANNEL_LIST_FAILURE = 'GET_CHANNEL_LIST_FAILURE',
  GET_INTENTS_LIST = 'GET_INTENTS_LIST',
  GET_INTENTS_LIST_SUCCESS = 'GET_INTENTS_LIST_SUCCESS',
  GET_INTENTS_LIST_FAILURE = 'GET_INTENTS_LIST_FAILURE',
  DELETE_INTENT_MESSAGE = 'DELETE_INTENT_MESSAGE',
  GET_INTENT_MESSAGE = 'GET_INTENT_MESSAGE',
  GET_INTENT_MESSAGE_SUCCESS = 'GET_INTENT_MESSAGE_SUCCESS',
  GET_INTENT_MESSAGE_FAILURE = 'GET_INTENT_MESSAGE_FAILURE',
  SET_SHARING_TOKEN = 'SET_SHARING_TOKEN',
  SET_TUTORIAL_VISIBLE = 'SWITCH_TUTORIAL_VISIBLE',
}

// Account state type

export type DashboardState = {
  tutorialVisible: boolean;
  sharingToken?: string;
  filter: Filter;
  intents: {
    default?: string;
    loading: boolean;
    list: string[];
    error: string;
  };
  refs: {
    list: string[];
    loading: boolean;
    error: string;
  };
  channels: {
    list: string[];
    loading: boolean;
    error: string;
  };
  intentMessages: { intent: string; list: string[]; loading: boolean };
  current: ({ key: string } & DashboardData) | null;
  list: {
    [key: string]: DashboardData;
  };
  siderMenuItem: string;
  filterPanelHidden: boolean;
  dbDownload: {
    loading: boolean;
    error: string;
  };
};

export type DashboardData = {
  name: string;
  loaded: boolean;
};

export type DatesRange = {
  startDate: Moment;
  endDate: Moment;
};

export type Filter = {
  ref?: string;
  channel?: string;
  datesRange: DatesRange;
};

// Asynchronous action types

// Action types

export interface UpdateMetricRef extends AnyAction {
  type: DashboardActionTypeConstant;
  key: string;
  ref: any;
}

export interface UpdateRef extends AnyAction {
  type: DashboardActionTypeConstant;
  ref: string;
}

export interface UpdateChannel extends AnyAction {
  type: DashboardActionTypeConstant;
  ref: string;
}

export interface UpdateDatesRange extends AnyAction {
  type: DashboardActionTypeConstant;
  datesRange: DatesRange;
}

export interface GetIntentMessageSuccess extends AnyAction {
  type: DashboardActionTypeConstant;
  message: string;
}

export interface GetIntentMessageFailure extends AnyAction {
  type: DashboardActionTypeConstant;
  error: string;
}

export interface GetRefsList extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface GetRefsListSuccess extends AnyAction {
  type: DashboardActionTypeConstant;
  data: string[];
}

export interface GetRefsListFailure extends AnyAction {
  type: DashboardActionTypeConstant;
  error: string;
}

export interface GetChannelList extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface GetChannelListSuccess extends AnyAction {
  type: DashboardActionTypeConstant;
  data: string[];
}

export interface GetChannelListFailure extends AnyAction {
  type: DashboardActionTypeConstant;
  error: string;
}

export interface GetIntentsList extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface GetIntentsListSuccess extends AnyAction {
  type: DashboardActionTypeConstant;
  data: string[];
}

export interface GetIntentsListFailure extends AnyAction {
  type: DashboardActionTypeConstant;
  error: string;
}
export interface SwitchSiderMenuItem extends AnyAction {
  type: DashboardActionTypeConstant;
  siderMenuItem: string;
}

export interface SetCurrentDashboard extends AnyAction {
  type: DashboardActionTypeConstant;
  key: string;
}

export interface SetDashboardLoaded extends AnyAction {
  type: DashboardActionTypeConstant;
  key: string;
}

export interface ResetDashboardsLoaded extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface DashboardDownload extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface DashboardDownloadSuccess extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface DashboardDownloadFailure extends AnyAction {
  type: DashboardActionTypeConstant;
  error: string;
}

export interface ToggleFilterPanel extends AnyAction {
  type: DashboardActionTypeConstant;
}

export interface SetSharingToken extends AnyAction {
  type: DashboardActionTypeConstant;
  token: string | undefined;
}

export interface SetTutorialVisible extends AnyAction {
  type: DashboardActionTypeConstant;
  visible: boolean;
}

export type DashboardActionType =
  | UpdateMetricRef
  | UpdateRef
  | UpdateChannel
  | UpdateDatesRange
  | GetIntentMessageSuccess
  | GetIntentMessageFailure
  | GetRefsList
  | GetRefsListSuccess
  | GetRefsListFailure
  | GetChannelList
  | GetChannelListSuccess
  | GetChannelListFailure
  | GetIntentsList
  | GetIntentsListSuccess
  | GetIntentsListFailure
  | SwitchSiderMenuItem
  | SetCurrentDashboard
  | SetDashboardLoaded
  | ResetDashboardsLoaded
  | DashboardDownload
  | DashboardDownloadSuccess
  | DashboardDownloadFailure
  | ToggleFilterPanel
  | SetSharingToken
  | SetTutorialVisible;
