import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import {
  LinkCreator,
  Clustering,
  SearchMessages,
  Summarization,
} from 'components/sections/tools';

const ToolsRoutes = () => (
  <Switch>
    <Redirect exact from="/tools" to="/tools/link-creator" />
    <Route path="/tools/link-creator" component={LinkCreator} />
    <Route path="/tools/clustering" component={Clustering} />
    <Route path="/tools/search-messages" component={SearchMessages} />
    <Route path="/tools/summarization" component={Summarization} />
    <Redirect from="*" to="/tools" />
  </Switch>
);

export default ToolsRoutes;
