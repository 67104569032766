import React from 'react';
import { Trans } from 'react-i18next';
import moment, { Moment } from 'moment';
import styled, { css } from 'styled-components';
import {
  Select,
  Radio,
  Cascader,
  Skeleton,
  Button,
  DatePicker,
  Tooltip,
} from 'antd';
import { grey } from '@ant-design/colors';

import {
  MetricPanel,
  ActionButton as ActionButtonType,
  MetricPanelOptionsSelected,
} from 'types/metrics';

const { Option } = Select;
const { RangePicker } = DatePicker;

const LabelWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  > * {
    flex-grow: 1;
  }
`;

const Label = styled.span`
  margin-right: 10px;
  white-space: nowrap;
`;

const Ellipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const getPanelWithLabel = (
  PanelComponent: JSX.Element,
  key: string,
  label?: string,
) =>
  label ? (
    <LabelWrapper key={key}>
      <Label>
        <Trans>{label}</Trans>:
      </Label>
      {PanelComponent}
    </LabelWrapper>
  ) : (
    PanelComponent
  );

export const renderMetricPanel = (
  panel: MetricPanel,
  onChange?: (options: MetricPanelOptionsSelected) => void,
) => {
  const getOnChange = (val: MetricPanelOptionsSelected['key']) =>
    onChange
      ? onChange({ [panel.key]: val })
      : panel.onChange && panel.onChange({ [panel.key]: val });

  const filterSelect = (key: string) => {
    const filters = ['ref', 'channel', 'datesRange'];
    return filters.includes(key);
  };

  if (panel.loading) {
    return getPanelWithLabel(
      <Skeleton.Input
        key={`skeleton-${panel.key}`}
        size="small"
        style={{ width: `${filterSelect(panel.key) ? '150px' : '225px'}` }}
        active
      />,
      panel.key,
      panel.label,
    );
  }

  switch (panel.type) {
    case 'select':
      return getPanelWithLabel(
        <Select
          key={panel.key}
          value={panel.value}
          allowClear={panel.allowClear}
          showSearch={panel.showSearch === undefined ? true : panel.showSearch}
          placeholder={<Trans>{panel.placeholder || 'Please, select'}</Trans>}
          getPopupContainer={(triggerNode: HTMLDivElement) =>
            triggerNode.parentNode?.parentNode
          }
          size="small"
          optionFilterProp="children"
          onChange={getOnChange}
          style={{
            width: `${filterSelect(panel.key) ? '150px' : '225px'}`,
            maxWidth: '100%',
          }}
        >
          {panel.options?.map(option => (
            <Option key={option.value} value={option.value}>
              <Tooltip
                title={option.value?.length > 29 && option.value}
                placement="right"
              >
                <Ellipsis>
                  <Trans>{option.label}</Trans>
                </Ellipsis>
              </Tooltip>
            </Option>
          ))}
        </Select>,
        panel.key,
        panel.label,
      );

    case 'radio':
      return getPanelWithLabel(
        <Radio.Group
          key={panel.key}
          value={panel.value}
          size="small"
          onChange={(e: { target: { value: string } }) =>
            getOnChange(e.target.value)
          }
          style={{ display: 'flex' }}
        >
          {panel.options?.map(option => (
            <Radio.Button key={option.value} value={option.value}>
              <Trans>{option.label}</Trans>
            </Radio.Button>
          ))}
        </Radio.Group>,
        panel.key,
        panel.label,
      );

    case 'cascader':
      return getPanelWithLabel(
        <Cascader
          key={panel.key}
          value={panel.value}
          allowClear={panel.allowClear}
          showSearch={panel.showSearch === undefined ? true : panel.showSearch}
          getPopupContainer={(triggerNode: HTMLDivElement) =>
            triggerNode.parentNode?.parentNode
          }
          options={panel.options}
          size="small"
          changeOnSelect
          onChange={(
            _value: string,
            selectedOptions: { value: string; label: string }[],
          ) => getOnChange(selectedOptions.map(option => option.value))}
          loadData={panel.loadOptions}
          placeholder={<Trans>{panel.placeholder || 'Please, select'}</Trans>}
          style={{
            width: `${filterSelect(panel.key) ? '150px' : '225px'}`,
            maxWidth: '100%',
          }}
        />,
        panel.key,
        panel.label,
      );

    case 'range-picker':
      return getPanelWithLabel(
        <RangePicker
          key={panel.key}
          size="small"
          allowClear={panel.allowClear}
          value={panel.value}
          getPopupContainer={(triggerNode: HTMLDivElement) =>
            triggerNode.parentNode?.parentNode
          }
          disabledDate={(current: Moment) => current > moment().endOf('day')}
          onChange={getOnChange}
          style={{ minWidth: '150px' }}
        />,
        panel.key,
        panel.label,
      );

    default:
      return null;
  }
};

const ActionButton = styled(Button)`
  ${({ type }) =>
    type !== 'primary' &&
    css`
      color: ${grey[6]};
    `}
`;

export const renderMetricActionButton = ({
  key,
  type,
  icon,
  text,
  visible = true,
  onClick,
}: ActionButtonType) =>
  visible ? (
    <ActionButton
      key={key}
      type={type}
      onClick={onClick}
      icon={icon}
      size="small"
      shape={!text && 'circle'}
    >
      <Trans>{text}</Trans>
    </ActionButton>
  ) : null;
