/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table, Spin, Empty } from 'antd';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { getMisunderstoodSum } from 'actions/tools';

import { AccountState } from 'types/account';
import { DashboardState } from 'types/dashboard';
import { downloadTableCSV } from 'actions/dashboard';
import { RootState } from 'reducers';
import { SummarizationOptions, ToolsState } from 'types/tools';
import { ToolsFilter, openInfoModal } from 'helpers/commonComponents';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 68px);
  thead {
    font-size: 16px;
    span {
      font-weight: 600;
    }
  }
`;

const getSummarizationColumns = () => [
  {
    title: <Trans>Message</Trans>,
    dataIndex: 'message',
    key: 'message',
  },
];

const Summarization = () => {
  const misunderstoodSum = useSelector<
    RootState,
    ToolsState['misunderstoodSum']
  >(state => state.tools.misunderstoodSum);

  const bots = useSelector<RootState, AccountState['bots']>(
    state => state.account.bots,
  );

  const dbDownload = useSelector<RootState, DashboardState['dbDownload']>(
    state => state.dashboard.dbDownload,
  );
  const [params, setParams] = useState({} as SummarizationOptions);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const actionButtons = [
    {
      key: 'download',
      type: 'link',
      icon: dbDownload.loading ? <LoadingOutlined /> : <DownloadOutlined />,
      visible: !!misunderstoodSum.list.length,
      onClick: downloadTableCSV(
        misunderstoodSum.list,
        'Summarization',
        ['key', 'Message'],
        bots.list.find(bot => bot.id === params.botId)?.name,
        {
          startDate: params.datesRange?.[0],
          endDate: params.datesRange?.[1],
        },
      ),
    },
    {
      key: 'info',
      type: 'link',
      icon: <InfoCircleOutlined />,
      visible: true,
      onClick: () => {
        openInfoModal(
          t('Summarization of misunderstood messages'),
          t('misunderstoodSum-info'),
        );
      },
    },
  ];

  const filterConfig = {
    search: {
      visible: false,
      text: '',
    },
    intent: false,
    startButton: 'Launch',
  };

  const handleFormFinish = () => {
    dispatch(getMisunderstoodSum(params));
  };

  return (
    <Wrapper>
      <ToolsFilter
        mainData={misunderstoodSum}
        filterConfig={filterConfig}
        actionButtons={actionButtons}
        handleFormFinish={handleFormFinish}
        params={params}
        setParams={setParams}
      />

      <TableWrapper>
        {misunderstoodSum.loading ? (
          <Spin spinning style={{ margin: 'auto' }} />
        ) : !misunderstoodSum.list.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 'auto' }}
          />
        ) : (
          <Table
            dataSource={misunderstoodSum.list}
            columns={getSummarizationColumns()}
            loading={misunderstoodSum.loading}
            pagination={{
              pageSize: 15,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
      </TableWrapper>
    </Wrapper>
  );
};
export default Summarization;
