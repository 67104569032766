import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment, { Moment } from 'moment';
import { Space } from 'antd';
import { gold } from '@ant-design/colors';

import * as dashboardActions from 'actions/dashboard';
import { RootState } from 'reducers';
import { renderMetricPanel } from 'helpers/renderers';
import { DashboardState, DatesRange } from 'types/dashboard';

const FilterWrapper = styled.div`
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}

  /* height: 60px;
  padding: 0 24px;
  margin-bottom: 20px; */

  padding: 0 24px 15px;
  margin-bottom: 20px;

  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  }

  transition: box-shadow 0.2s;
`;

const Title = styled.h2`
  color: ${gold[5]};
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 15px 20px 0 0;
`;

const PanelsWrapper = styled(Space)`
  overflow: hidden;
  justify-content: flex-end;

  .ant-space-item {
    display: inline-block;
    margin-top: 15px;
  }

  .ant-space-item:not(:first-child) {
    margin-left: 30px;
  }

  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

export const getFilterPanels = (
  refs: DashboardState['refs'],
  channels: DashboardState['channels'],
  referral: string | undefined,
  channel: string | undefined,
  datesRange: DatesRange,
  updateRef: (ref?: string) => void,
  updateChannel: (channel?: string) => void,
  updateDatesRange: (datesRange: DatesRange) => void,
) => [
  {
    key: 'channel',
    type: 'select',
    allowClear: true,
    showSearch: true,
    placeholder: 'Select channel',
    label: 'Channel',
    loading: channels.loading,
    value: channel,
    options: channels.list.map(c => ({
      value: c,
      label: c,
    })),
    onChange: (options?: { channel?: string } | null) =>
      updateChannel(options?.channel),
  },
  {
    key: 'ref',
    type: 'select',
    allowClear: true,
    showSearch: true,
    placeholder: 'Select ref',
    label: 'Referral',
    loading: refs.loading,
    value: referral,
    options: refs.list.map(ref => ({
      value: ref,
      label: ref,
    })),
    onChange: (options?: { ref?: string } | null) => updateRef(options?.ref),
  },
  {
    key: 'datesRange',
    type: 'select',
    placeholder: 'Select range',
    label: 'Dates range',
    value: (
      <>
        <span>{datesRange.endDate.diff(datesRange.startDate, 'd') + 1} </span>
        <span>
          {i18n.t('day', {
            count: datesRange.endDate.diff(datesRange.startDate, 'd') + 1,
          })}
        </span>
      </>
    ),
    options: [
      { value: '1 day', label: '1 day' },
      { value: '2 days', label: '2 days' },
      { value: '7 days', label: '7 days' },
      { value: '30 days', label: '30 days' },
    ],
    onChange: (options?: { datesRange?: string } | null) => {
      if (!options?.datesRange) return;

      updateDatesRange({
        startDate: moment().subtract(
          +options.datesRange.split(' ')[0] - 1,
          'd',
        ),
        endDate: moment(),
      });
    },
  },
  {
    key: 'customDates',
    type: 'range-picker',
    allowClear: false,
    placeholder: 'Choose dates',
    label: 'Custom dates',
    value: [datesRange.startDate, datesRange.endDate],
    onChange: (options?: { customDates?: Moment[] } | null) => {
      if (!options?.customDates) return;

      updateDatesRange({
        startDate: options?.customDates[0],
        endDate: options?.customDates[1],
      });
    },
  },
];

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const FilterPanel = ({
  hidden,
  referral,
  refsList,
  channels,
  channel,
  datesRange,
  updateRef,
  updateChannel,
  updateDatesRange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FilterWrapper hidden={hidden}>
      <Title>{t('Filter')}</Title>

      <PanelsWrapper size={0}>
        {getFilterPanels(
          refsList,
          channels,
          referral,
          channel,
          datesRange,
          updateRef,
          updateChannel,
          updateDatesRange,
        ).map(panel => renderMetricPanel(panel))}
      </PanelsWrapper>
    </FilterWrapper>
  );
};

const mapStateToProps = (store: RootState) => ({
  hidden: store.dashboard.filterPanelHidden,
  referral: store.dashboard.filter.ref,
  channel: store.dashboard.filter.channel,
  datesRange: store.dashboard.filter.datesRange,
  refsList: store.dashboard.refs,
  channels: store.dashboard.channels,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateRef: dashboardActions.updateRef,
      updateChannel: dashboardActions.updateChannel,
      updateDatesRange: dashboardActions.updateDatesRange,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
