/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table, Spin, Empty } from 'antd';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { AccountState } from 'types/account';
import { DashboardState } from 'types/dashboard';
import { downloadSearchMessagesCSV } from 'actions/dashboard';
import { getMessages } from 'actions/tools';
import { RootState } from 'reducers';
import { SearchMessagesOptions, ToolsState } from 'types/tools';
import { usePromptError } from 'helpers/hooks';
import { ToolsFilter, openInfoModal } from 'helpers/commonComponents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 68px);
  thead {
    font-size: 16px;
    span {
      font-weight: 600;
    }
  }
`;

// const FullSessionButton = styled(Button)`
//   color: ${gold[5]};
//   border-color: ${gold[5]};
//   border-radius: 4px;
//   padding: 0px;
//   width: 32px;
// `;

// const openInfoModal = (title: string, content: string) => {
//   Modal.info({
//     title,
//     maskClosable: true,
//     content: <p>{content}</p>,
//     icon: <InfoCircleOutlined style={{ color: gold[5] }} />,
//     onOk() {},
//   });
// };

const getSearchMessagesColumns = () => [
  {
    title: <Trans>Message</Trans>,
    dataIndex: 'message',
    key: 'message',
    width: '50%',
    sorter: (a: { message: string }, b: { message: string }) =>
      a.message?.localeCompare(b.message),
  },
  {
    title: <Trans>Intent</Trans>,
    dataIndex: 'intent',
    key: 'intent',
    width: 100,
    sorter: (a: { intent: string }, b: { intent: string }) =>
      a.intent?.localeCompare(b.intent),
  },
  {
    title: <Trans>Timestamp</Trans>,
    dataIndex: 'timeStamp',
    key: 'timeStamp',
    width: 170,
    sorter: (a: { timeStamp: string }, b: { timeStamp: string }) =>
      a.timeStamp?.localeCompare(b.timeStamp),
  },
  {
    title: <Trans>User ID</Trans>,
    dataIndex: 'userId',
    key: 'userId',
    width: 100,
    sorter: (a: { userId: string }, b: { userId: string }) =>
      a.userId?.localeCompare(b.userId),
  },
  // {
  //   title: <Trans>Session ID</Trans>,
  //   dataIndex: 'sessionId',
  //   key: 'sessionId',
  //   width: 100,
  //   sorter: (a: { sessionId: string }, b: { sessionId: string }) =>
  //     a.sessionId?.localeCompare(b.sessionId),
  // },
  // {
  //   title: '',
  //   dataIndex: 'seeFull',
  //   key: 'seeFull',
  //   width: 50,
  //   render: (_: any, record: { key: number }) => (
  //     <FullSessionButton disabled>
  //       <RightOutlined />
  //     </FullSessionButton>
  //   ),
  // },
];

const SearchMessages = () => {
  const searchMessage = useSelector<RootState, ToolsState['searchMessage']>(
    state => state.tools.searchMessage,
  );
  const bots = useSelector<RootState, AccountState['bots']>(
    state => state.account.bots,
  );
  const dbDownload = useSelector<RootState, DashboardState['dbDownload']>(
    state => state.dashboard.dbDownload,
  );
  const [params, setParams] = useState({} as SearchMessagesOptions);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  usePromptError(searchMessage.prompt, searchMessage.error);

  const filterConfig = {
    search: {
      visible: true,
      text: 'Find any messages',
    },
    intent: true,
    startButton: 'Search',
  };

  const actionButtons = [
    {
      key: 'download',
      type: 'link',
      icon: dbDownload.loading ? <LoadingOutlined /> : <DownloadOutlined />,
      visible: !!searchMessage.list.length,
      onClick: () =>
        dispatch(
          downloadSearchMessagesCSV(
            searchMessage.list,
            bots.list.find(bot => bot.id === params.botId)?.name,
            {
              startDate: params.datesRange?.[0],
              endDate: params.datesRange?.[1],
            },
          ),
        ),
    },
    {
      key: 'info',
      type: 'link',
      icon: <InfoCircleOutlined />,
      visible: true,
      onClick: () => {
        openInfoModal(t('Message search'), t('MessageSearchInfo'));
      },
    },
  ];

  const handleFormFinish = () => {
    dispatch(getMessages(params));
  };

  return (
    <Wrapper>
      <ToolsFilter
        mainData={searchMessage}
        filterConfig={filterConfig}
        actionButtons={actionButtons}
        handleFormFinish={handleFormFinish}
        params={params}
        setParams={setParams}
      />

      <TableWrapper>
        {searchMessage.loading ? (
          <Spin spinning style={{ margin: 'auto' }} />
        ) : !searchMessage.list.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 'auto' }}
          />
        ) : (
          <Table
            dataSource={searchMessage.list}
            columns={getSearchMessagesColumns()}
            loading={searchMessage.loading}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
      </TableWrapper>
    </Wrapper>
  );
};
export default SearchMessages;
