/* eslint-disable camelcase */
import { RefObject } from 'react';
import { AnyAction } from 'redux';
import { Moment } from 'moment';

// Action constants

export enum MetricsActionTypeConstant {
  UPDATE_METRIC_REF = 'UPDATE_METRIC_REF',
  GET_NEW_USERS = 'GET_NEW_USERS',
  GET_NEW_USERS_SUCCESS = 'GET_NEW_USERS_SUCCESS',
  GET_NEW_USERS_FAILURE = 'GET_NEW_USERS_FAILURE',
  GET_UNIQUE_USERS = 'GET_UNIQUE_USERS',
  GET_UNIQUE_USERS_SUCCESS = 'GET_UNIQUE_USERS_SUCCESS',
  GET_UNIQUE_USERS_FAILURE = 'GET_UNIQUE_USERS_FAILURE',
  GET_SESSIONS = 'GET_SESSIONS',
  GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE',
  GET_LAST_MSG_DATE = 'GET_LAST_MSG_DATE',
  GET_LAST_MSG_DATE_SUCCESS = 'GET_LAST_MSG_DATE_SUCCESS',
  GET_LAST_MSG_DATE_FAILURE = 'GET_LAST_MSG_DATE_FAILURE',
  GET_ENGAGEMENT = 'GET_ENGAGEMENT',
  GET_ENGAGEMENT_SUCCESS = 'GET_ENGAGEMENT_SUCCESS',
  GET_ENGAGEMENT_FAILURE = 'GET_ENGAGEMENT_FAILURE',
  GET_TOTAL_USERS = 'GET_TOTAL_USERS',
  GET_TOTAL_USERS_SUCCESS = 'GET_TOTAL_USERS_SUCCESS',
  GET_TOTAL_USERS_FAILURE = 'GET_TOTAL_USERS_FAILURE',
  GET_INTENTS = 'GET_INTENTS',
  GET_INTENTS_SUCCESS = 'GET_INTENTS_SUCCESS',
  GET_INTENTS_FAILURE = 'GET_INTENTS_FAILURE',
  GET_ENTRANCE_INTENTS = 'GET_ENTRANCE_INTENTS',
  GET_ENTRANCE_INTENTS_SUCCESS = 'GET_ENTRANCE_INTENTS_SUCCESS',
  GET_ENTRANCE_INTENTS_FAILURE = 'GET_ENTRANCE_INTENTS_FAILURE',
  GET_LEAVING_INTENTS = 'GET_LEAVING_INTENTS',
  GET_LEAVING_INTENTS_SUCCESS = 'GET_LEAVING_INTENTS_SUCCESS',
  GET_LEAVING_INTENTS_FAILURE = 'GET_LEAVING_INTENTS_FAILURE',
  GET_ENTRANCE_INTENTS_RETURNING = 'GET_ENTRANCE_INTENTS_RETURNING',
  GET_ENTRANCE_INTENTS_RETURNING_SUCCESS = 'GET_ENTRANCE_INTENTS_RETURNING_SUCCESS',
  GET_ENTRANCE_INTENTS_RETURNING_FAILURE = 'GET_ENTRANCE_INTENTS_RETURNING_FAILURE',
  GET_LEAVING_INTENTS_RETURNING = 'GET_LEAVING_INTENTS_RETURNING',
  GET_LEAVING_INTENTS_RETURNING_SUCCESS = 'GET_LEAVING_INTENTS_RETURNING_SUCCESS',
  GET_LEAVING_INTENTS_RETURNING_FAILURE = 'GET_LEAVING_INTENTS_RETURNING_FAILURE',
  GET_CONVERSION = 'GET_CONVERSION',
  GET_CONVERSION_SUCCESS = 'GET_CONVERSION_SUCCESS',
  GET_CONVERSION_FAILURE = 'GET_CONVERSION_FAILURE',
  GET_FLOW_THROUGH_HANDLER = 'GET_FLOW_THROUGH_HANDLER',
  GET_FLOW_THROUGH_HANDLER_SUCCESS = 'GET_FLOW_THROUGH_HANDLER_SUCCESS',
  GET_FLOW_THROUGH_HANDLER_FAILURE = 'GET_FLOW_THROUGH_HANDLER_FAILURE',
  GET_CONVERSATIONAL_FLOW = 'GET_CONVERSATIONAL_FLOW',
  GET_CONVERSATIONAL_FLOW_SUCCESS = 'GET_CONVERSATIONAL_FLOW_SUCCESS',
  GET_CONVERSATIONAL_FLOW_FAILURE = 'GET_CONVERSATIONAL_FLOW_FAILURE',
  GET_UNSUBSCRIBED = 'GET_UNSUBSCRIBED',
  GET_UNSUBSCRIBED_SUCCESS = 'GET_UNSUBSCRIBED_SUCCESS',
  GET_UNSUBSCRIBED_FAILURE = 'GET_UNSUBSCRIBED_FAILURE',
  GET_MISUNDERSTOOD_COUNT = 'GET_MISUNDERSTOOD_COUNT',
  GET_MISUNDERSTOOD_COUNT_SUCCESS = 'GET_MISUNDERSTOOD_COUNT_SUCCESS',
  GET_MISUNDERSTOOD_COUNT_FAILURE = 'GET_MISUNDERSTOOD_COUNT_FAILURE',
  GET_MISUNDERSTOOD = 'GET_MISUNDERSTOOD',
  GET_MISUNDERSTOOD_SUCCESS = 'GET_MISUNDERSTOOD_SUCCESS',
  GET_MISUNDERSTOOD_FAILURE = 'GET_MISUNDERSTOOD_FAILURE',
  GET_MESSAGES_COUNT = 'GET_MESSAGES_COUNT',
  GET_MESSAGES_COUNT_SUCCESS = 'GET_MESSAGES_COUNT_SUCCESS',
  GET_MESSAGES_COUNT_FAILURE = 'GET_MESSAGES_COUNT_FAILURE',
  GET_MESSAGES = 'GET_MESSAGES',
  GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE',
  GET_SESSION_STATS = 'GET_SESSION_STATS',
  GET_SESSION_STATS_SUCCESS = 'GET_SESSION_STATS_SUCCESS',
  GET_SESSION_STATS_FAILURE = 'GET_SESSION_STATS_FAILURE',
  GET_INTENT_STATS = 'GET_INTENT_STATS',
  GET_INTENT_STATS_SUCCESS = 'GET_INTENT_STATS_SUCCESS',
  GET_INTENT_STATS_FAILURE = 'GET_INTENT_STATS_FAILURE',
  GET_NPS = 'GET_NPS',
  GET_NPS_SUCCESS = 'GET_NPS_SUCCESS',
  GET_NPS_FAILURE = 'GET_NPS_FAILURE',
  GET_LIKES = 'GET_LIKES',
  GET_LIKES_SUCCESS = 'GET_LIKES_SUCCESS',
  GET_LIKES_FAILURE = 'GET_LIKES_FAILURE',
  GET_REFS = 'GET_REFS',
  GET_REFS_SUCCESS = 'GET_REFS_SUCCESS',
  GET_REFS_FAILURE = 'GET_REFS_FAILURE',
  GET_RETENTION = 'GET_RETENTION',
  GET_RETENTION_SUCCESS = 'GET_RETENTION_SUCCESS',
  GET_RETENTION_FAILURE = 'GET_RETENTION_FAILURE',
  GET_ACTIVITY = 'GET_ACTIVITY',
  GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS',
  GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE',
  GET_MISUNDERSTOOD_INTENTS = 'GET_MISUNDERSTOOD_INTENTS',
  GET_MISUNDERSTOOD_INTENTS_SUCCESS = 'GET_MISUNDERSTOOD_INTENTS_SUCCESS',
  GET_MISUNDERSTOOD_INTENTS_FAILURE = 'GET_MISUNDERSTOOD_INTENTS_FAILURE',
  GET_SESSIONS_COUNT = 'GET_SESSIONS_COUNT',
  GET_SESSIONS_COUNT_SUCCESS = 'GET_SESSIONS_COUNT_SUCCESS',
  GET_SESSIONS_COUNT_FAILURE = 'GET_SESSIONS_COUNT_FAILURE',
  GET_FUNNEL = 'GET_FUNNEL',
  GET_FUNNEL_SUCCESS = 'GET_FUNNEL_SUCCESS',
  GET_FUNNEL_FAILURE = 'GET_FUNNEL_FAILURE',
  GET_INTENTS_TREE = 'GET_INTENTS_TREE',
  GET_INTENTS_TREE_SUCCESS = 'GET_INTENTS_TREE_SUCCESS',
  GET_DAU_MAU = 'GET_DAU_MAU',
  GET_DAU_MAU_SUCCESS = 'GET_DAU_MAU_SUCCESS',
  GET_DAU_MAU_FAILURE = 'GET_DAU_MAU_FAILURE',
  SET_METRIC_INTENT = 'SET_METRIC_INTENT',
}

// Metrics state type

export type MetricsState = {
  metric: {
    usersTotal: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      intent?: string;
      value: number;
      loaded: boolean;
      error: string;
    };
    conversion: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: number;
      percent: boolean;
      intent?: string;
      loaded: boolean;
      error: string;
    };
    sessionsCount: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: number;
      loaded: boolean;
      error: string;
    };
    newUsers: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    uniqueUsers: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    unsubscribed: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    engagement: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      view: 'time' | 'sessions' | 'interactions';
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    sessions: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    lastMsgDate: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: string;
      loaded: boolean;
      error: string;
    };
    sessionsAvgTime: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: number;
      loaded: boolean;
      error: string;
    };
    sessionsAvgCount: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: number;
      loaded: boolean;
      error: string;
    };
    nps: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      value: number | null;
      loaded: boolean;
      error: string;
    };
    likes: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: LikesData;
      percent: boolean;
      loaded: boolean;
      error: string;
    };
    refs: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: TableData;
      loaded: boolean;
      error: string;
    };
    messages: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: TableData;
      intent?: string;
      loaded: boolean;
      error: string;
    };
    messagesCount: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    misunderstood: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: TableData;
      loaded: boolean;
      error: string;
    };
    misunderstoodCount: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      percent: boolean;
      loaded: boolean;
      error: string;
    };
    misunderstoodIntents: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: TableData;
      loaded: boolean;
      error: string;
    };
    topIntents: {
      title: string;
      url: {
        all: string;
        entrance: string;
        leaving: string;
        entranceReturning: string;
        leavingReturning: string;
      };
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      view:
        | 'all'
        | 'entrance'
        | 'leaving'
        | 'entranceReturning'
        | 'leavingReturning';
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    dauMau: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      loaded: boolean;
      error: string;
    };
    retention: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      data?: TableData;
      existing: boolean;
      percent: boolean;
      loaded: boolean;
      error: string;
    };
    intentStats: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ChartData;
      intent?: string;
      loaded: boolean;
      error: string;
    };
    activity: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: ScatterData;
      loaded: boolean;
      error: string;
    };
    funnel: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: FunnelData;
      intents: string[];
      intentsTree: MetricPanelOption[];
      loaded: boolean;
      error: string;
    };
    flowThroughHandler: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: SankeyData;
      intent?: string;
      loaded: boolean;
      error: string;
    };
    conversationalFlow: {
      title: string;
      url: string;
      ref?: RefObject<HTMLDivElement>;
      type: string;
      boards: string[];
      chart?: SankeyData;
      intent?: string;
      loaded: boolean;
      error: string;
    };
  };
};

export type MetricName = keyof MetricsState['metric'];

// Asynchronous action types

export type ChartData = {
  [key: string]: string | number;
}[];

export type TableDataBackend = { [key: string]: string }[];

export type TableData = {
  columns: {
    title: string;
    dataIndex: string;
    key: string;
    sorter?: Function;
  }[];
  rows: ({ key: string } & TableDataBackend[0])[];
  total?: number;
};

export type SankeyDataBackend = {
  intents: string[];
  count: number;
}[];

export type SankeyData = {
  nodes: { name: string }[];
  links: { source: number; target: number; value: number }[];
};

export type ScatterDataBackend = {
  date: string;
  time: { hour: string; count: string }[];
}[];

export type ScatterData = {
  date: string;
  hour: number;
  count: number;
}[];

export type FunnelData = {
  count: number;
  percent: number;
  intent: string;
  fill: string;
}[];

export type LikesData = {
  dislikes: number;
  like_percent: number;
  likes: number;
};

export type MetricPanel = {
  key: string;
  type: string;
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  label?: string;
  loading?: boolean;
  value?: string | string[] | Moment[] | JSX.Element;
  options?: MetricPanelOption[];
  loadOptions?: Function;
  onChange?: (value: MetricPanelOptionsSelected | null) => void;
};

export type ActionButton = {
  key: string;
  type: string;
  text?: string;
  icon?: JSX.Element;
  visible: boolean;
  onClick: (() => void) | undefined;
};

export type MetricPanelOption = {
  value: string;
  label?: string;
  loading?: boolean;
  children?: MetricPanelOption[];
};

export type MetricPanelOptionsSelected = {
  [key: string]: string | number | string[] | Moment[];
};

export type IntentPanelOptionSelected = {
  intent?: string;
};

export type PaginationTablePage = {
  page?: number;
};

export type UnitPanelOptionSelected = {
  unit?: string;
};

// Action types

export interface UpdateMetricRef extends AnyAction {
  type: MetricsActionTypeConstant;
  key: string;
  ref: any;
}

export interface GetNewUsers extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetNewUsersSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetNewUsersFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetUniqueUsers extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetUniqueUsersSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetUniqueUsersFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetSessions extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetSessionsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetSessionsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetLastMsgDate extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetLastMsgDateSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  value: string;
}

export interface GetLastMsgDateFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetEngagement extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetEngagementSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetEngagementFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetUsersTotal extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetUsersTotalSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: number;
}

export interface GetUsersTotalFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetIntents extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetIntentsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
}

export interface GetIntentsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetConversion extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetConversionSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  value: number;
}

export interface GetConversionFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetFlowThroughHandler extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetFlowThroughHandlerSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  chart: SankeyData;
}

export interface GetFlowThroughHandlerFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetConversationalFlow extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetConversationalFlowSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  chart: SankeyData;
}

export interface GetConversationalFlowFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetUnsubscribed extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetUnsubscribedSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetUnsubscribedFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetMisunderstoodCount extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetMisunderstoodCountSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
  percent: boolean;
}

export interface GetMisunderstoodCountFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetMisunderstood extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetMisunderstoodSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
}

export interface GetMisunderstoodFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetMessagesCount extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetMessagesCountSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ChartData;
}

export interface GetMessagesCountFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetMessages extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetMessagesSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
}

export interface GetMessagesFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetSessionStats extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetSessionStatsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  avgTime: number;
  avgSessions: number;
}

export interface GetSessionStatsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetNPS extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetNPSSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  value: number | null;
}

export interface GetNPSFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetLikes extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetLikesSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: LikesData;
}

export interface GetLikesFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetRefs extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetRefsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
}

export interface GetRefsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetIntentStats extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetIntentStatsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  chart: { date: string; entered: number; exit: number }[];
}

export interface GetIntentStatsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetRetention extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetRetentionSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
  existing: boolean;
  percent: boolean;
}

export interface GetRetentionFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetActivity extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetActivitySuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: ScatterData;
}

export interface GetActivityFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetFunnel extends AnyAction {
  type: MetricsActionTypeConstant;
  intents: string[];
  intentsTree: MetricPanelOption[];
}

export interface GetFunnelSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  chart: FunnelData;
}

export interface GetFunnelFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetMisunderstoodIntents extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetMisunderstoodIntentsSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: TableData;
}

export interface GetMisunderstoodIntentsFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetSessionsCount extends AnyAction {
  type: MetricsActionTypeConstant;
}

export interface GetSessionsCountSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  data: number;
}

export interface GetSessionsCountFailure extends AnyAction {
  type: MetricsActionTypeConstant;
  error: string;
}

export interface GetIntentsTree extends AnyAction {
  type: MetricsActionTypeConstant;
  intent: string;
}

export interface GetIntentsTreeSuccess extends AnyAction {
  type: MetricsActionTypeConstant;
  intentsTree: MetricPanelOption[];
}

export interface SetMetricIntent extends AnyAction {
  type: MetricsActionTypeConstant;
  metric: MetricName;
  intent?: string;
  intents?: string[];
  intentsTree?: MetricPanelOption[];
}

export type MetricsActionType =
  | UpdateMetricRef
  | GetUsersTotal
  | GetUsersTotalSuccess
  | GetUsersTotalFailure
  | GetConversion
  | GetConversionSuccess
  | GetConversionFailure
  | GetSessionsCount
  | GetSessionsCountSuccess
  | GetSessionsCountFailure
  | GetNewUsers
  | GetNewUsersSuccess
  | GetNewUsersFailure
  | GetUniqueUsers
  | GetUniqueUsersSuccess
  | GetUniqueUsersFailure
  | GetUnsubscribed
  | GetUnsubscribedSuccess
  | GetUnsubscribedFailure
  | GetEngagement
  | GetEngagementSuccess
  | GetEngagementFailure
  | GetSessions
  | GetSessionsSuccess
  | GetSessionsFailure
  | GetLastMsgDate
  | GetLastMsgDateSuccess
  | GetLastMsgDateFailure
  | GetSessionStats
  | GetSessionStatsSuccess
  | GetSessionStatsFailure
  | GetNPS
  | GetNPSSuccess
  | GetNPSFailure
  | GetLikes
  | GetLikesSuccess
  | GetLikesFailure
  | GetRefs
  | GetRefsSuccess
  | GetRefsFailure
  | GetMessages
  | GetMessagesSuccess
  | GetMessagesFailure
  | GetMessagesCount
  | GetMessagesCountSuccess
  | GetMessagesCountFailure
  | GetMisunderstood
  | GetMisunderstoodSuccess
  | GetMisunderstoodFailure
  | GetMisunderstoodCount
  | GetMisunderstoodCountSuccess
  | GetMisunderstoodCountFailure
  | GetMisunderstoodIntents
  | GetMisunderstoodIntentsSuccess
  | GetMisunderstoodIntentsFailure
  | GetIntents
  | GetIntentsSuccess
  | GetIntentsFailure
  | GetRetention
  | GetRetentionSuccess
  | GetRetentionFailure
  | GetIntentStats
  | GetIntentStatsSuccess
  | GetIntentStatsFailure
  | GetActivity
  | GetActivitySuccess
  | GetActivityFailure
  | GetFunnel
  | GetFunnelSuccess
  | GetFunnelFailure
  | GetFlowThroughHandler
  | GetFlowThroughHandlerSuccess
  | GetFlowThroughHandlerFailure
  | GetConversationalFlow
  | GetConversationalFlowSuccess
  | GetConversationalFlowFailure
  | GetIntentsTree
  | GetIntentsTreeSuccess
  | SetMetricIntent;
