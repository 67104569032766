import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import styled from 'styled-components';
import moment from 'moment';

import {
  AreaChart,
  BarChart,
  Bar,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Scatter,
  ScatterChart,
  Funnel,
  FunnelChart,
  LabelList,
  Legend,
  Line,
  LineChart,
  Text,
} from 'recharts';
import { Layout, Result } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { grey } from '@ant-design/colors';

import { getChartMargins } from 'helpers/utils';
import { RootState } from 'reducers';

import { setDashboardLoaded, resetDashboardsLoaded } from 'actions/dashboard';
import * as metrics from 'actions/metrics';
import * as dashboardActions from 'actions/dashboard';
import * as accountActions from 'actions/account';
import { Chart, Stat, Table, Sankey } from 'components/metrics';
import { MetricsState } from 'types/metrics';
import IntentsTooltip from 'components/sections/dashboards/IntentsTooltip';

import Header from './Header';
import FilterPanel from './FilterPanel';
import { Label } from 'recharts';

const { Content } = Layout;

const MetricsWrapper = styled(Content)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  align-items: stretch;
  margin: -5px;
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Dashboards = ({
  bots,
  dashboards,
  referral,
  channel,
  currentDashboard,
  datesRange,
  refs,
  intents,
  metric,
  search,
  loadRefsList,
  loadChannelList,
  loadIntentsList,
  loadIntentsTree,
  loadMetric,
  setDashLoaded,
  resetDashsLoaded,
  switchBot,
  updRef,
  updChannel,
  updDatesRange,
  setMetricIntent,
  getIntentMsg,
}: Props) => {
  const { t } = useTranslation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const loadMetrics = useCallback(
    (boardKey: string, initLoad: boolean = false) => {
      if (dashboards.all?.loaded || dashboards[boardKey]?.loaded) {
        return;
      }

      const loadedBoards = Object.entries(dashboards)
        .filter(([, val]) => val.loaded)
        .map(([key]) => key);

      // case when all dashboards except 'all' were loaded already
      if (
        !loadedBoards.includes('all') &&
        Object.keys(dashboards).length - loadedBoards.length === 1
      ) {
        setDashLoaded('all');
        return;
      }

      const currentMetrics = Object.entries(metric).reduce(
        (prev, [key, metricData]) =>
          metricData.boards.includes(boardKey) &&
          key !== 'sessionsAvgTime' && // sessionsAvgTime loaded already -> not included
          !metricData.boards.some(b => loadedBoards.indexOf(b) >= 0) // = metric isn't already loaded
            ? [...prev, key]
            : prev,
        [] as string[],
      );

      currentMetrics.forEach((metricKey: string, index: number) => {
        setTimeout(
          // if options = null, the metric intents aren't reset
          // if options = undefined, the metric intents are reset
          () => (loadMetric as any)[metricKey](initLoad ? null : undefined),
          index * 100,
        );
      });

      setDashLoaded(boardKey);
    },
    [metric, dashboards, loadMetric, setDashLoaded],
  );

  const refsIntentsLoaded = useRef(false);

  const reloadMetrics = useCallback(
    (boardKey?: string) => {
      // load refs, channels, intents first, then load metrics
      if (!refsIntentsLoaded.current) {
        loadRefsList();
        loadChannelList();
        loadIntentsList();
        resetDashsLoaded();
        refsIntentsLoaded.current = true;
      } else if (
        !refs.loading &&
        boardKey &&
        paramsSet.current.includes('ref') &&
        paramsSet.current.includes('channel')
      ) {
        loadMetrics(boardKey, firstLoad.current);
        refsIntentsLoaded.current = false;
        firstLoad.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refs.loading, loadIntentsList, loadRefsList, resetDashsLoaded],
  );

  // the list of metric names containing intent(s) filter
  const metricsWithIntents = useMemo<string[]>(
    () =>
      Object.entries(metric).reduce(
        (prevIntents, [metricKey, metricData]: [string, any]) =>
          urlParams.get(metricKey) &&
          metricData.boards.includes(urlParams.get('dashboard')) &&
          ('intent' in metricData || 'intents' in metricData)
            ? [...prevIntents, metricKey]
            : prevIntents,
        [] as string[],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const paramsSet = useRef<string[]>([]);

  // initial load + reload (clear load status for all boards)
  useEffect(() => {
    if (
      bots.loading ||
      !currentDashboard ||
      !paramsSet.current.includes('datesRange')
    ) {
      return;
    }

    reloadMetrics(currentDashboard.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral, channel, datesRange, bots, refs.loading, paramsSet.current.length]);

  const firstLoad = useRef(true);

  // section switch
  useEffect(() => {
    if (!currentDashboard) return;

    if (!firstLoad.current) {
      loadMetrics(currentDashboard.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDashboard]);

  useEffect(() => {
    if (bots.loading) return;

    if (!paramsSet.current.includes('intents')) {
      // set intent values from URL params to existing metrics
      metricsWithIntents.forEach(metricName => {
        if (urlParams.has(metricName)) {
          setMetricIntent(
            metricName as keyof MetricsState['metric'],
            metricName === 'funnel'
              ? String(urlParams.get('funnel')).split(',')
              : urlParams.get(metricName),
          );
        }
      });
      paramsSet.current.push('intents');
    }

    if (!paramsSet.current.includes('botId')) {
      if (urlParams.has('botId')) {
        switchBot(urlParams.get('botId') || '');
      }
      paramsSet.current.push('botId');
    }

    if (!paramsSet.current.includes('datesRange')) {
      if (urlParams.has('startDate') && urlParams.has('endDate')) {
        updDatesRange({
          startDate: moment(urlParams.get('startDate')),
          endDate: moment(urlParams.get('endDate')),
        });
      }
      paramsSet.current.push('datesRange');
    }

    if (
      refs.loading ||
      !paramsSet.current.includes('botId') ||
      !paramsSet.current.includes('datesRange')
    )
      return;

    if (!paramsSet.current.includes('ref')) {
      if (urlParams.has('ref')) {
        updRef(urlParams.get('ref') || undefined);
      }
      paramsSet.current.push('ref');
    }

    if (!paramsSet.current.includes('channel')) {
      if (urlParams.has('channel')) {
        updChannel(urlParams.get('channel') || undefined);
      }
      paramsSet.current.push('channel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots.loading, refs.loading, switchBot, updDatesRange, updRef, updChannel]);

  return (
    <Content style={{ flexShrink: 0 }}>
      <Header loadMetrics={() => reloadMetrics(currentDashboard?.key)} />

      <FilterPanel />

      {!bots.current ? (
        <Result
          title={
            <p style={{ color: grey[0] }}>
              Sorry, but you have no bots connected to the account
            </p>
          }
          icon={<InfoCircleFilled style={{ color: grey[0] }} />}
        />
      ) : (
        <MetricsWrapper>
          <Stat
            metricKey="usersTotal"
            size={3}
            value={`${metric.usersTotal.value}`}
            onReload={loadMetric.usersTotal}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.usersTotal.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
            ]}
          />

          <Stat
            metricKey="conversion"
            empty={!intents.default}
            size={3}
            value={`${metric.conversion.value}${
              metric.conversion.percent ? '%' : ''
            }`}
            onReload={loadMetric.conversion}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.conversion.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
              {
                key: 'unit',
                type: 'radio',
                loading: false,
                value: metric.conversion.percent ? 'percent' : 'count',
                options: [
                  { value: 'percent', label: '%' },
                  { value: 'count', label: '#' },
                ],
              },
            ]}
          />

          <Stat
            metricKey="sessionsCount"
            size={3}
            value={`${metric.sessionsCount.value}`}
            onReload={loadMetric.sessionsCount}
          />

          <Chart size={3} metricKey="newUsers" onReload={loadMetric.newUsers}>
            <AreaChart
              data={metric.newUsers.chart}
              margin={getChartMargins('count', metric.newUsers.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Users'),
                  angle: -90,
                  dy: 15,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.newUsers.chart).left + 6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                fill="#8884d8"
                isAnimationActive={false}
              />
            </AreaChart>
          </Chart>

          <Chart
            size={3}
            metricKey="uniqueUsers"
            onReload={loadMetric.uniqueUsers}
          >
            <AreaChart
              data={metric.uniqueUsers.chart}
              margin={getChartMargins('count', metric.uniqueUsers.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Users'),
                  angle: -90,
                  dy: 15,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.uniqueUsers.chart).left +
                    6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                fill="#8884d8"
                isAnimationActive={false}
              />
            </AreaChart>
          </Chart>

          <Chart
            size={3}
            metricKey="unsubscribed"
            onReload={loadMetric.unsubscribed}
          >
            <BarChart
              data={metric.unsubscribed.chart}
              margin={getChartMargins('count', metric.unsubscribed.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Users'),
                  angle: -90,
                  dy: 15,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.unsubscribed.chart).left +
                    6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Chart
            size={3}
            metricKey="engagement"
            onReload={loadMetric.engagement}
            panels={[
              {
                key: 'view',
                type: 'radio',
                loading: false,
                value: metric.engagement.view,
                options: [
                  { value: 'time', label: t('Time') },
                  { value: 'interactions', label: t('Interactions') },
                  { value: 'sessions', label: t('Sessions') },
                ],
              },
            ]}
          >
            <AreaChart
              data={metric.engagement.chart}
              margin={getChartMargins('count', metric.engagement.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: `${t(
                    `${metric.engagement.view[0].toUpperCase()}${metric.engagement.view.slice(
                      1,
                    )}`,
                  )}/${
                    metric.engagement.view === 'sessions'
                      ? t('Users')
                      : t('Sessions')
                  }`,
                  angle: -90,
                  dy: 60,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.engagement.chart).left + 6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                fill="#8884d8"
                isAnimationActive={false}
              />
            </AreaChart>
          </Chart>

          <Chart size={3} metricKey="sessions" onReload={loadMetric.sessions}>
            <BarChart
              data={metric.sessions.chart}
              margin={getChartMargins('count', metric.sessions.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Sessions'),
                  angle: -90,
                  dy: 20,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.sessions.chart).left + 6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Stat
            metricKey="lastMsgDate"
            size={3}
            value={
              parseInt(metric.lastMsgDate.value)
              ? `${parseInt(metric.lastMsgDate.value)} ${i18n.t('hour', {
                count: parseInt(metric.lastMsgDate.value),
              })} ${t(' ago')}`
              : t('< 30 minutes ago')
            }
            onReload={loadMetric.lastMsgDate}
          />

          <Stat
            value={`${metric.sessionsAvgTime.value}`}
            size={3}
            metricKey="sessionsAvgTime"
            onReload={loadMetric.sessionsAvgTime}
          />

          <Stat
            value={`${metric.sessionsAvgCount.value}`}
            size={3}
            metricKey="sessionsAvgCount"
            onReload={loadMetric.sessionsAvgCount}
          />

          <Stat
            metricKey="nps"
            empty={metric.nps.value === null}
            size={3}
            value={`⭐\xa0\xa0${metric.nps.value}`}
            onReload={loadMetric.nps}
          />

          <Stat
            metricKey="likes"
            empty={metric.likes.data === undefined}
            size={3}
            value={metric.likes.percent
              ? `👍\xa0\xa0${metric.likes.data?.like_percent}%`
              : `👍\xa0\xa0${metric.likes.data?.likes} / ${metric.likes.data?.dislikes}`}
            onReload={loadMetric.likes}
            panels={[
              {
                key: 'unit',
                type: 'radio',
                loading: false,
                value: metric.likes.percent ? 'percent' : 'count',
                options: [
                  { value: 'percent', label: '%' },
                  { value: 'count', label: '#' },
                ],
              },
            ]}
          />

          <Table
            metricKey="refs"
            size={3}
            data={metric.refs.data}
            onReload={loadMetric.refs}
          />

          <Table
            data={metric.messages.data}
            size={3}
            metricKey="messages"
            onReload={loadMetric.messages}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.messages.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
            ]}
          />

          <Chart
            size={3}
            metricKey="messagesCount"
            onReload={loadMetric.messagesCount}
          >
            <BarChart
              data={metric.messagesCount.chart}
              margin={getChartMargins('count', metric.messagesCount.chart)}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Messages'),
                  angle: -90,
                  dy: 25,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.messagesCount.chart).left +
                    6,
                }}
                dataKey="count"
              />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Table
            data={metric.misunderstood.data}
            size={3}
            metricKey="misunderstood"
            onReload={loadMetric.misunderstood}
          />

          <Chart
            size={3}
            metricKey="misunderstoodCount"
            onReload={loadMetric.misunderstoodCount}
            panels={[
              {
                key: 'unit',
                type: 'radio',
                loading: false,
                value: metric.misunderstoodCount.percent ? 'percent' : 'count',
                options: [
                  { value: 'percent', label: '%' },
                  { value: 'count', label: '#' },
                ],
              },
            ]}
          >
            <BarChart
              data={metric.misunderstoodCount.chart}
              margin={getChartMargins(
                'count',
                metric.misunderstoodCount.chart?.map(m => ({
                  ...m,
                  count: +`${m.count}${
                    metric.misunderstoodCount.percent ? '0' : ''
                  }}`, // add one more symbol space for percent sign
                })),
              )}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{ value: t('Date'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                label={{
                  value: t('Messages'),
                  angle: -90,
                  dy: 25,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.misunderstoodCount.chart)
                      .left + (metric.misunderstoodCount.percent ? -5 : 5),
                }}
                dataKey="count"
                tickFormatter={(tick: number) =>
                  `${tick}${metric.misunderstoodCount.percent ? '%' : ''}`
                }
              />
              <Tooltip
                formatter={(value: string | number, name: string) =>
                  name === 'count' && metric.misunderstoodCount.percent
                    ? `${value}%`
                    : value
                }
              />
              <Bar dataKey="count" fill="#8884d8" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Table
            data={metric.misunderstoodIntents.data}
            size={3}
            metricKey="misunderstoodIntents"
            onReload={loadMetric.misunderstoodIntents}
          />

          <Chart
            size={1.5}
            height={3}
            aspect={3 / 1}
            metricKey="topIntents"
            onReload={loadMetric.topIntents}
            panels={[
              {
                key: 'view',
                type: 'radio',
                loading: false,
                value: metric.topIntents.view,
                options: [
                  { value: 'all', label: t('All') },
                  { value: 'entrance', label: t('Entrance') },
                  { value: 'leaving', label: t('Leaving') },
                  { value: 'entranceReturning', label: t('Entrance for returning users') },
                  { value: 'leavingReturning', label: t('Leaving for returning users') },
                ],
              },
            ]}
          >
            <BarChart
              data={metric.topIntents.chart}
              margin={getChartMargins('count', metric.topIntents.chart)}
              onClick={(e: any) => {
                if (e?.activeLabel) {
                  getIntentMsg(e?.activeLabel);
                }
              }}
              cursor="pointer"
            >
              <XAxis
                dataKey="intent"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={65}
                tickFormatter={(tick: string) =>
                  tick.length > 10 ? `${tick.slice(0,10)}...` : tick
                }
              />
              <YAxis
                label={{
                  value: t('Count'),
                  angle: -90,
                  dy: 25,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('count', metric.topIntents.chart).left +
                    6,
                }}
                dataKey="count"
              />
              <Tooltip content={
                <IntentsTooltip />
              } />
              <Bar dataKey="count" fill="#8884d8" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Chart
            size={3}
            metricKey="dauMau"
            empty={!metric.dauMau.chart || !metric.dauMau.chart?.length}
            onReload={loadMetric.dauMau}
          >
            <LineChart
              data={metric.dauMau.chart}
              margin={getChartMargins(
                'count',
                metric.dauMau.chart?.map(rec => ({
                  ...rec,
                  count: Math.max(
                    rec.daily as number,
                    rec.monthly as number,
                    rec.ratio as number,
                  ),
                })),
              )}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                label={{
                  value: t('Date'),
                  dy: 10,
                  offset: 0,
                  position: 'insideBottomRight',
                }}
              />
              <YAxis
                label={{
                  value: t('Count'),
                  angle: -90,
                  dy: 20,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins(
                      'count',
                      metric.dauMau.chart?.map(rec => ({
                        ...rec,
                        count: Math.max(
                          rec.daily as number,
                          rec.monthly as number,
                          rec.ratio as number,
                        ),
                      })),
                    ).left + 6,
                }}
              />
              <Tooltip
                formatter={(value: string, name: string) => {
                  switch (name) {
                    case 'daily':
                      return [value, 'DAU'];

                    case 'monthly':
                      return [value, 'MAU'];

                    case 'ratio':
                      return [value, 'DAU/MAU'];

                    default:
                      return 'Unknown';
                  }
                }}
              />
              <Legend
                formatter={(value: string) => {
                  switch (value) {
                    case 'daily':
                      return 'DAU';

                    case 'monthly':
                      return 'MAU';

                    case 'ratio':
                      return 'DAU/MAU';

                    default:
                      return 'Unknown';
                  }
                }}
              />
              <Line
                type="monotone"
                dataKey="daily"
                stroke="#8884d8"
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="monthly"
                stroke="#82ca9d"
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="ratio"
                stroke="#5192ca"
                isAnimationActive={false}
              />
            </LineChart>
          </Chart>

          <Table
            data={metric.retention.data}
            pageSize={7}
            size={1.5}
            height={3}
            metricKey="retention"
            onReload={loadMetric.retention}
            panels={[
              {
                key: 'novelty',
                type: 'radio',
                loading: false,
                value: metric.retention.existing ? 'existing' : 'new',
                options: [
                  { value: 'existing', label: t('Existing') },
                  { value: 'new', label: t('New') },
                ],
              },
              {
                key: 'unit',
                type: 'radio',
                loading: false,
                value: metric.retention.percent ? 'percent' : 'count',
                options: [
                  { value: 'percent', label: '%' },
                  { value: 'count', label: '#' },
                ],
              },
            ]}
          />

          <Chart
            size={3}
            metricKey="intentStats"
            empty={
              !intents.default ||
              !metric.intentStats.chart ||
              !metric.intentStats.chart?.length
            }
            onReload={loadMetric.intentStats}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.intentStats.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
            ]}
          >
            <BarChart
              data={metric.intentStats.chart}
              margin={getChartMargins(
                'count',
                metric.intentStats.chart?.map(rec => ({
                  ...rec,
                  count: Math.max(rec.entered as number, rec.exit as number),
                })),
              )}
            >
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                label={{
                  value: t('Date'),
                  dy: 10,
                  offset: 0,
                  position: 'insideBottomRight',
                }}
              />
              <YAxis
                label={{
                  value: t('Sessions'),
                  angle: -90,
                  dy: 20,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins(
                      'count',
                      metric.intentStats.chart?.map(rec => ({
                        ...rec,
                        count: Math.max(
                          rec.entered as number,
                          rec.exit as number,
                        ),
                      })),
                    ).left + 6,
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="entered" fill="#8884d8" isAnimationActive={false} />
              <Bar dataKey="exit" fill="#82ca9d" isAnimationActive={false} />
            </BarChart>
          </Chart>

          <Chart
            size={1.5}
            metricKey="activity"
            empty={!metric.activity.chart || !metric.activity.chart?.length}
            onReload={loadMetric.activity}
          >
            <ScatterChart
              margin={getChartMargins('date', metric.activity.chart)}
            >
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="hour"
                name="Hour"
                ticks={[...new Array(24).keys()]}
                tickCount={24}
                interval={0}
                allowDuplicatedCategory={false}
                label={{ value: t('Hour'), offset: 0, position: 'bottom' }}
              />
              <YAxis
                reversed
                label={{
                  value: t('Weekday'),
                  angle: -90,
                  dy: 15,
                  position: 'insideLeft',
                  offset:
                    -getChartMargins('date', metric.activity.chart).left + 6,
                }}
                type="category"
                dataKey="date"
                name="Day"
                interval={0}
                allowDuplicatedCategory={false}
              />
              <ZAxis
                type="number"
                dataKey="count"
                name="Count"
                range={[10, 800]}
              />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Scatter
                data={metric.activity.chart}
                fill="#8884d8"
                isAnimationActive={false}
              />
            </ScatterChart>
          </Chart>

          <Chart
            size={1.5}
            metricKey="funnel"
            empty={
              !intents.default ||
              !metric.funnel.chart ||
              !metric.funnel.chart?.length
            }
            onReload={loadMetric.funnel}
            panels={[
              {
                key: 'intents',
                type: 'cascader',
                allowClear: true,
                showSearch: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.funnel.intents,
                options: metric.funnel.intentsTree,
                loadOptions: loadIntentsTree,
              },
            ]}
          >
            <FunnelChart
              margin={{
                right:
                  metric.funnel.chart?.length &&
                  metric.funnel.chart[0].intent.length * 3,
              }}
            >
              <Funnel
                dataKey="count"
                nameKey="intent"
                data={metric.funnel.chart}
                isAnimationActive={false}
              >

                <LabelList
                  position="right"
                  fill="#000"
                  stroke="none"
                  dataKey="intent"
                />

              </Funnel>
            </FunnelChart>
          </Chart>

          <Sankey
            data={metric.flowThroughHandler.chart}
            margin={{ right: 100, bottom: 5 }}
            size={1.5}
            metricKey="flowThroughHandler"
            empty={
              !intents.default ||
              !metric.flowThroughHandler.chart ||
              !metric.flowThroughHandler.chart?.nodes.length
            }
            onReload={loadMetric.flowThroughHandler}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.flowThroughHandler.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
            ]}
          />

          <Sankey
            data={metric.conversationalFlow.chart}
            margin={{ right: 140, bottom: 5 }}
            size={1.5}
            metricKey="conversationalFlow"
            empty={
              !intents.default ||
              !metric.conversationalFlow.chart ||
              !metric.conversationalFlow.chart?.nodes.length
            }
            onReload={loadMetric.conversationalFlow}
            panels={[
              {
                key: 'intent',
                type: 'select',
                allowClear: true,
                placeholder: t('Select intent'),
                loading: intents.loading,
                value: metric.conversationalFlow.intent,
                options: intents.list.map(i => ({ value: i, label: i })),
              },
            ]}
          />
        </MetricsWrapper>
      )}
    </Content>
  );
};

const mapStateToProps = (store: RootState) => ({
  bots: store.account.bots,
  referral: store.dashboard.filter.ref,
  channel: store.dashboard.filter.channel,
  intents: store.dashboard.intents,
  refs: store.dashboard.refs,
  datesRange: store.dashboard.filter.datesRange,
  metric: store.metrics.metric,
  dashboards: store.dashboard.list,
  currentDashboard: store.dashboard.current,
  search: store.router.location.search,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      updRef: dashboardActions.updateRef,
      updChannel: dashboardActions.updateChannel,
      updDatesRange: dashboardActions.updateDatesRange,
      loadRefsList: dashboardActions.getRefsList,
      loadChannelList: dashboardActions.getChannelList,
      loadIntentsList: dashboardActions.getIntentsList,
      loadIntentsTree: metrics.getIntentsTree,
      setDashLoaded: setDashboardLoaded,
      resetDashsLoaded: resetDashboardsLoaded,
      switchBot: accountActions.switchBot,
      setMetricIntent: metrics.setMetricIntent,
      getIntentMsg: dashboardActions.getIntentMessage,
    },
    dispatch,
  ),
  loadMetric: bindActionCreators(
    {
      usersTotal: metrics.getUsersTotal,
      conversion: metrics.getConversion,
      sessionsCount: metrics.getSessionsCount,
      newUsers: metrics.getNewUsers,
      uniqueUsers: metrics.getUniqueUsers,
      unsubscribed: metrics.getUnsubscribed,
      engagement: metrics.getEngagement,
      sessions: metrics.getSessions,
      lastMsgDate: metrics.getLastMsgDate,
      sessionsAvgTime: metrics.getSessionStats,
      sessionsAvgCount: metrics.getSessionStats,
      nps: metrics.getNPS,
      likes: metrics.getLikes,
      refs: metrics.getRefs,
      messages: metrics.getMessages,
      messagesCount: metrics.getMessagesCount,
      misunderstood: metrics.getMisunderstood,
      misunderstoodCount: metrics.getMisunderstoodCount,
      misunderstoodIntents: metrics.getMisunderstoodIntents,
      topIntents: metrics.getIntents,
      dauMau: metrics.getDauMau,
      retention: metrics.getRetention,
      intentStats: metrics.getIntentStats,
      activity: metrics.getActivity,
      funnel: metrics.getFunnel,
      flowThroughHandler: metrics.getFlowThroughHandler,
      conversationalFlow: metrics.getConversationalFlow,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
