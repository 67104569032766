import { Dispatch } from 'redux';
import moment from 'moment';
import axios from 'axios';
import * as airtable from 'helpers/airtable';

import {
  DatesRange,
  DashboardState,
  DashboardActionType,
  DashboardActionTypeConstant,
} from 'types/dashboard';
import { MetricsState } from 'types/metrics';

import { RootState } from 'reducers';

import createPDF from 'helpers/pdf';
import {
  createAllOneCSV,
  createFullZipCSV,
  createSearchMessagesCSV,
  createClusteringCSV,
  createTableCSV,
} from 'helpers/csv';
import { getMetric } from 'api/metrics';
import { loadRefsList, loadIntentsList, loadChannelList } from 'api/dashboard';

export const switchSiderMenuItem = (
  siderMenuItem: string,
): DashboardActionType => ({
  type: DashboardActionTypeConstant.SWITCH_SIDER_MENU_ITEM,
  siderMenuItem,
});

export const toggleFilterPanel = () => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { filterPanelHidden } = getState().dashboard;
  localStorage.setItem('filterPanelHidden', (!filterPanelHidden).toString());

  dispatch({
    type: DashboardActionTypeConstant.TOGGLE_FILTER_PANEL,
  });
};

export const setCurrentDashboard = (key: string | null) => ({
  type: DashboardActionTypeConstant.SET_CURRENT_DASHBOARD,
  key,
});

export const setDashboardLoaded = (key: string) => ({
  type: DashboardActionTypeConstant.SET_DASHBOARD_LOADED,
  key,
});

export const resetDashboardsLoaded = () => ({
  type: DashboardActionTypeConstant.RESET_DASHBOARDS_LOADED,
});

const dashboardDownloadSuccess = (): DashboardActionType => ({
  type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD_SUCCESS,
});

const dashboardDownloadFailure = (error: string): DashboardActionType => ({
  type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD_FAILURE,
  error,
});

export const downloadPDF = (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
  botName: string | undefined,
  datesRange: DatesRange,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });

  try {
    if (!currentDashboard) throw new Error('There is no current dashboard!');
    if (!botName) throw new Error('There is no current bot!');

    const doc = await createPDF(metrics, currentDashboard, botName, datesRange);
    doc.save(
      `${botName}_${currentDashboard.name}_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.pdf`,
    );

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const downloadCSV = (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
  botName: string | undefined,
  datesRange: DatesRange,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });

  try {
    if (!currentDashboard) throw new Error('There is no current dashboard!');
    if (!botName) throw new Error('There is no current bot!');

    const csvBlob = createAllOneCSV(
      metrics,
      currentDashboard,
      botName,
      datesRange,
    );

    saveAs(
      csvBlob,
      `${botName}_${currentDashboard.name}_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.csv`,
    );

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const downloadSearchMessagesCSV = (
  data: any,
  botName: string | undefined,
  datesRange: DatesRange,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });
  try {
    if (!botName) throw new Error('There is no current bot!');

    const csvBlob = createSearchMessagesCSV(data, botName, datesRange);

    saveAs(
      csvBlob,
      `${botName}_Search-Messages_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.csv`,
    );

    airtable.sendRecord({ 'Number of CSV reports': 1 });

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const downloadClusteringCSV = (
  jobId: number,
  botName: string | undefined,
  datesRange: DatesRange,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });
  try {
    if (!botName) throw new Error('There is no current bot!');

    const {
      data: { result },
    } = await axios.get(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/cluster/labels/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    const csvBlob = createClusteringCSV(result, botName, datesRange);

    saveAs(
      csvBlob,
      `${botName}_Log clustering_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.csv`,
    );

    airtable.sendRecord({ 'Number of CSV reports': 1 });

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const downloadTableCSV = (
  data: any,
  currentTool: string,
  headers: string[],
  botName: string | undefined,
  datesRange: DatesRange,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });
  try {
    if (!botName) throw new Error('There is no current bot!');

    const csvBlob = createTableCSV(
      data,
      currentTool,
      headers,
      botName,
      datesRange,
    );

    saveAs(
      csvBlob,
      `${botName}_${currentTool}_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.csv`,
    );

    airtable.sendRecord({ 'Number of CSV reports': 1 });

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const downloadFullCSV = (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
  botName: string | undefined,
) => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardActionTypeConstant.DASHBOARD_DOWNLOAD });

  try {
    if (!currentDashboard) throw new Error('There is no current dashboard!');
    if (!botName) throw new Error('There is no current bot!');

    const csvBlob = await createFullZipCSV(metrics, currentDashboard);

    saveAs(
      csvBlob,
      `${botName}_${currentDashboard.name}_${moment().format(
        'DD.MM.YYYY',
      )}-${moment().format('HH:mm:ss')}.zip`,
    );

    dispatch(dashboardDownloadSuccess());
  } catch (error) {
    dispatch(dashboardDownloadFailure('Error!'));
  }
};

export const updateRef = (_ref?: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { dashboard } = getState();

  const ref =
    (_ref !== undefined && dashboard.refs.list.includes(_ref)) ||
    _ref === undefined
      ? _ref
      : dashboard.filter.ref;

  if (ref === undefined) {
    localStorage.removeItem('filterPanelRef');
  } else {
    localStorage.setItem('filterPanelRef', ref);
  }

  dispatch({
    type: DashboardActionTypeConstant.UPDATE_REF,
    ref,
  });
};

export const updateChannel = (_channel?: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { dashboard } = getState();

  const channel =
    (_channel !== undefined && dashboard.channels.list.includes(_channel)) ||
    _channel === undefined
      ? _channel
      : dashboard.filter.channel;

  if (channel === undefined) {
    localStorage.removeItem('filterPanelChannel');
  } else {
    localStorage.setItem('filterPanelChannel', channel);
  }

  dispatch({
    type: DashboardActionTypeConstant.UPDATE_CHANNEL,
    channel,
  });
};

export const updateDatesRange = (datesRange: DatesRange) => {
  localStorage.setItem('filterPanelStartDate', datesRange.startDate.toString());
  localStorage.setItem('filterPanelEndDate', datesRange.endDate.toString());

  return {
    type: DashboardActionTypeConstant.UPDATE_DATES_RANGE,
    datesRange,
  };
};

const getRefsListSuccess = (data: string[]): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_REFS_LIST_SUCCESS,
  data,
});

const getRefsListFailure = (error: string): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_REFS_LIST_FAILURE,
  error,
});

export const getRefsList = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: DashboardActionTypeConstant.GET_REFS_LIST });

    try {
      const refsList = await loadRefsList(
        bots.current.id,
        {
          datesRange: filter.datesRange,
        },
        sharingToken,
      );

      dispatch(getRefsListSuccess(refsList.map(r => r.ref)));
    } catch (error) {
      dispatch(getRefsListFailure('Error!'));
    }
  };
};

const getChannelListSuccess = (data: string[]): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_CHANNEL_LIST_SUCCESS,
  data,
});

const getChannelListFailure = (error: string): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_CHANNEL_LIST_FAILURE,
  error,
});

export const getChannelList = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: DashboardActionTypeConstant.GET_CHANNEL_LIST });

    try {
      const channelList = await loadChannelList(
        bots.current.id,
        {
          datesRange: filter.datesRange,
        },
        sharingToken,
      );

      dispatch(getChannelListSuccess(channelList));
    } catch (error) {
      dispatch(getChannelListFailure('Error!'));
    }
  };
};

const getIntentsListSuccess = (data: string[]): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_INTENTS_LIST_SUCCESS,
  data,
});

const getIntentsListFailure = (error: string): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_INTENTS_LIST_FAILURE,
  error,
});

export const getIntentsList = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: DashboardActionTypeConstant.GET_INTENTS_LIST });

    try {
      const intentsList = await loadIntentsList(
        bots.current.id,
        filter,
        sharingToken,
      );

      dispatch(getIntentsListSuccess(intentsList.map(i => i.intent)));
    } catch (error) {
      dispatch(getIntentsListFailure('Error!'));
    }
  };
};

export const clearIntentMessage = () => ({
  type: DashboardActionTypeConstant.DELETE_INTENT_MESSAGE,
});

const getIntentMessageSuccess = (
  intent: string,
  messages: string[],
): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_INTENT_MESSAGE_SUCCESS,
  intent,
  messages,
});

const getIntentMessageFailure = (error: string): DashboardActionType => ({
  type: DashboardActionTypeConstant.GET_INTENT_MESSAGE_FAILURE,
  error,
});

export const getIntentMessage = (intent: string) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: {
        sharingToken,
        intentMessages: { intent: intentInit },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;
    if (intentInit === intent) return;
    dispatch({ type: DashboardActionTypeConstant.GET_INTENT_MESSAGE });
    try {
      type Response = {
        result: { messages: string[] };
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        'intents/bot/messages',
        bots.current.id,
        undefined,
        { intent, count: 2 },
        sharingToken,
      );

      const messages = result.messages.map(msg =>
        `${msg.length > 200 ? `${msg.slice(0, 197)}...` : msg}`.replace(
          /\\n/g,
          '\n',
        ),
      );

      dispatch(getIntentMessageSuccess(intent, messages));
    } catch (error) {
      dispatch(getIntentMessageFailure('Loading failed :('));
    }
  };
};

export const setSharingToken = (token: string | undefined) => ({
  type: DashboardActionTypeConstant.SET_SHARING_TOKEN,
  token,
});

export const setTutorialVisible = (visible: boolean) => ({
  type: DashboardActionTypeConstant.SET_TUTORIAL_VISIBLE,
  visible,
});
