import {
  ToolsActionType,
  ToolsActionTypeConstant,
  ToolsState,
} from 'types/tools';

const initialState: ToolsState = {
  alerts: { list: [], loading: false, prompt: null, error: null },
  refLink: {
    link: null,
    prompt: null,
    error: null,
    loading: false,
  },
  clustering: {
    jobId: null,
    loading: false,
    prompt: null,
    error: null,
  },
  searchMessage: {
    list: [],
    loading: false,
    prompt: null,
    error: null,
  },
  misunderstoodSum: {
    list: [],
    loading: false,
    error: '',
  },
};

const toolsReducer = (
  state = initialState,
  action: ToolsActionType,
): ToolsState => {
  switch (action.type) {
    case ToolsActionTypeConstant.CREATE_REF_LINK: {
      return {
        ...state,
        refLink: {
          ...state.refLink,
          link: null,
          error: null,
          prompt: null,
          loading: true,
        },
      };
    }

    case ToolsActionTypeConstant.CREATE_REF_LINK_SUCCESS: {
      return {
        ...state,
        refLink: {
          ...state.refLink,
          link: action.link,
          prompt: action.prompt,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.CREATE_REF_LINK_FAILURE: {
      return {
        ...state,
        refLink: {
          ...state.refLink,
          error: action.error,
        },
      };
    }

    case ToolsActionTypeConstant.RESET_CLUSTERING: {
      return {
        ...state,
        clustering: {
          ...state.clustering,
          loading: false,
          prompt: null,
          error: null,
        },
      };
    }

    case ToolsActionTypeConstant.GET_CLUSTERING: {
      return {
        ...state,
        clustering: {
          ...state.clustering,
          loading: true,
          prompt: null,
          error: null,
        },
      };
    }

    case ToolsActionTypeConstant.GET_CLUSTERING_SUCCESS: {
      return {
        ...state,
        clustering: {
          ...state.clustering,
          jobId: action.jobId,
          prompt: action.prompt,
        },
      };
    }

    case ToolsActionTypeConstant.GET_CLUSTERING_FAILURE: {
      return {
        ...state,
        clustering: {
          ...state.clustering,
          prompt: null,
          error: action.error,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.CHECK_CLUSTERING_SUCCESS: {
      return {
        ...state,
        clustering: {
          ...state.clustering,
          loading: false,
          prompt: action.prompt,
          error: null,
        },
      };
    }

    case ToolsActionTypeConstant.GET_ALERTS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: null,
          prompt: null,
          loading: true,
        },
      };
    }

    case ToolsActionTypeConstant.GET_ALERTS_SUCCESS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          list: action.data,
          error: null,
          prompt: action.prompt,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.GET_ALERTS_FAILURE: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: action.error,
          prompt: null,
        },
      };
    }

    case ToolsActionTypeConstant.CREATE_ALERT: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: null,
          prompt: null,
          loading: true,
        },
      };
    }

    case ToolsActionTypeConstant.CREATE_ALERT_SUCCESS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          list: [...state.alerts.list, action.data],
          loading: false,
          error: null,
          prompt: action.prompt,
        },
      };
    }

    case ToolsActionTypeConstant.CREATE_ALERT_FAILURE: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: action.error,
          prompt: null,
        },
      };
    }

    case ToolsActionTypeConstant.DELETE_ALERT: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: null,
          prompt: null,
          loading: true,
        },
      };
    }

    case ToolsActionTypeConstant.DELETE_ALERT_SUCCESS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          list: state.alerts.list.filter(alert => alert.id !== action.id),
          loading: false,
          error: null,
          prompt: action.prompt,
        },
      };
    }

    case ToolsActionTypeConstant.DELETE_ALERT_FAILURE: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: action.error,
          prompt: null,
        },
      };
    }

    case ToolsActionTypeConstant.EDIT_ALERT: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: null,
          prompt: null,
          loading: true,
        },
      };
    }

    case ToolsActionTypeConstant.EDIT_ALERT_SUCCESS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          list: [
            ...state.alerts.list.filter(alert => alert.id !== action.oldID),
            action.data,
          ],
          loading: false,
          error: null,
          prompt: action.prompt,
        },
      };
    }

    case ToolsActionTypeConstant.EDIT_ALERT_FAILURE: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: action.error,
          prompt: null,
        },
      };
    }

    case ToolsActionTypeConstant.GET_USERS_MESSAGES: {
      return {
        ...state,
        searchMessage: {
          ...state.searchMessage,
          loading: true,
          prompt: null,
          error: null,
        },
      };
    }

    case ToolsActionTypeConstant.GET_USERS_MESSAGES_SUCCESS: {
      return {
        ...state,
        searchMessage: {
          ...state.searchMessage,
          list: action.data,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.GET_USERS_MESSAGES_FAILURE: {
      return {
        ...state,
        searchMessage: {
          ...state.searchMessage,
          error: action.error,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM: {
      return {
        ...state,
        misunderstoodSum: {
          ...state.misunderstoodSum,
          list: [],
          loading: true,
          error: '',
        },
      };
    }

    case ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM_SUCCESS: {
      return {
        ...state,
        misunderstoodSum: {
          ...state.misunderstoodSum,
          list: action.data,
          loading: false,
        },
      };
    }

    case ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM_FAILURE: {
      return {
        ...state,
        misunderstoodSum: {
          ...state.misunderstoodSum,
          loading: false,
          error: action.error,
        },
      };
    }

    default:
      return state;
  }
};

export default toolsReducer;
