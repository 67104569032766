import Icon, {
  RobotOutlined,
  FileDoneOutlined,
  SettingOutlined,
  RocketOutlined,
  DashboardOutlined,
  ToolOutlined,
  AlertOutlined,
} from '@ant-design/icons';

export type Section = {
  key: string;
  value?: string;
  icon?: typeof Icon;
  sup?: string;
  disabled?: boolean;
  children?: Section[];
  to?: string | null; // if null, there's no redirect, if undefined - address=/sectionKey/subSectionKey
  onClick?: () => void;
};

export type Sidebar = Section[];

export const sidebarConfig: Sidebar = [
  {
    key: 'dashboards',
    value: 'Dashboard',
    icon: DashboardOutlined,
    to: null,
    children: [
      { key: 'all', value: 'All metrics' },
      { key: 'users', value: 'Users' },
      { key: 'activity', value: 'Activity' },
      { key: 'conversations', value: 'Conversations' },
    ],
  },
  {
    key: 'tools',
    value: 'Tools',
    icon: ToolOutlined,
    to: null,
    children: [
      { key: 'link-creator', value: 'Link creator' },
      { key: 'clustering', value: 'Log clustering', sup: 'β' },
      { key: 'search-messages', value: 'Search messages' },
      {
        key: 'summarization',
        value: 'Summarization of misunderstood messages',
      },
    ],
  },
  // { key: 'reports', value: 'Reports', icon: FileDoneOutlined, disabled: true },
  { key: 'alerts', value: 'Alerts', icon: AlertOutlined },
  { key: 'my-bots', value: 'My bots', icon: RobotOutlined },
  {
    key: 'tutorial',
    value: 'Quick Tutorial',
    icon: RocketOutlined,
    to: null,
  },
  { key: 'settings', value: 'Settings', icon: SettingOutlined },
];
